import { Route } from 'vue-router'
import { Logger } from '@vue-storefront/core/lib/logger'
import Vue from 'vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
import { sha3_256 } from 'js-sha3'

export function afterEach(to: Route, from: Route) {
  Logger.info(`[Router] Route from ${from.name} to ${to.name}.`)()
}
