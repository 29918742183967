
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const activateAccount = ({ key, email }) => {
  const { storeCode } = currentStoreView()
  const url = `${config.api.url}/api/ext/user/activate`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ confirmationKey: key, email, storeCode })
  })
    .then(response => response.json())
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}
