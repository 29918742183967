// import { extendModule } from '@vue-storefront/core/lib/module'
import { VueStorefrontModule, extendModule } from '@vue-storefront/core/lib/module'
import { Catalog } from '@vue-storefront/core/modules/catalog'
import { Cart } from '@vue-storefront/core/modules/cart'
// import { Checkout } from '@vue-storefront/core/modules/checkout'
// import { Compare } from '@vue-storefront/core/modules/compare'
// import { Review } from '@vue-storefront/core/modules/review'
// import { Mailer } from '@vue-storefront/core/modules/mailer'
// import { Newsletter } from '@vue-storefront/core/modules/newsletter'
import { Notification } from '@vue-storefront/core/modules/notification'
// import { RecentlyViewed } from '@vue-storefront/core/modules/recently-viewed'
import { Url } from '@vue-storefront/core/modules/url'
// import { Homepage } from './homepage'
import { Claims } from './claims'
// import { PromotedOffers } from './promoted-offers'
import { Ui } from './ui-store'
// import { GoogleAnalytics } from './google-analytics';
// import { Hotjar } from './hotjar';
import { googleTagManager } from './google-tag-manager'
// import { AmpRenderer } from './amp-renderer'
import { PaymentBackendMethods } from './payment-backend-methods'
import { PaymentCashOnDelivery } from './payment-cash-on-delivery'
import { RawOutputExample } from './raw-output-example'
// import { OrderHistory } from './order-history'
import { Prismic } from './prismic'
import { RouterHooks } from './router-hook'
import { userExtend } from './user-extend'
import { cartExtend } from './cart-extend'
import { Wishlist } from './wishlist-extend'
import { ExternalCheckout } from './external-checkout'
import { Collections } from './zenith-collections'
import { Products } from './zenith-products'
import { Search } from './search'
import { Feedback } from './feedback'
import { Darkmode } from './darkmode'
import { GoogleRecaptcha } from './google-recaptcha'
import { Abtasty } from './abtasty'
import { ZenithUi } from './zenith-ui-store'
import { OneTrust } from './onetrust'
import { ZenithKonamiCode } from './zenith-konami-code'
import { PrismicToolbar } from './prismic-toolbar'
import { Konami } from './konami'
import { WatchMemory } from './watch-memory'

// import { Example } from './module-template'

// This is how you can extend any of VS modues
// const extendCartVuex = {
//   actions: {
//     load () {
//       Logger.info('New load function')()
//     }
//   }
//  }

//  const cartExtend = {
//   key: 'cart',
//   afterRegistration: function(isServer, config) {
//     Logger.info('New afterRegistration hook')()
//   },
//   store: { modules: [{ key: 'cart', module: extendCartVuex }] },
//  }

//  extendModule(cartExtend)

/**
 * Some of the modules are registered lazily only when components from the module are appearing on current page.
 * If you want to use this module in pages without its components you need to remember about registering module first
 * In VS 1.8 this modules will be seamlessly lazyLoaded after proper action dispatch
 * - Wishlist
 */

export const registerModules: VueStorefrontModule[] = [
  // Checkout,
  Catalog,
  Cart,
  // Compare,
  // Review,
  // Mailer,
  Wishlist,
  // Newsletter,
  Notification,
  Ui,
  // RecentlyViewed,
  // Homepage,
  Claims,
  // PromotedOffers,
  googleTagManager,
  PaymentBackendMethods,
  PaymentCashOnDelivery,
  RawOutputExample,
  Url,
  // OrderHistory,
  Prismic,
  RouterHooks,
  ExternalCheckout,
  Collections,
  Products,
  Search,
  Feedback,
  Darkmode,
  GoogleRecaptcha,
  Abtasty,
  ZenithUi,
  OneTrust,
  ZenithKonamiCode,
  PrismicToolbar,
  Konami,
  WatchMemory
]

extendModule(userExtend)
extendModule(cartExtend)
