import config from 'config'
// import Vue from 'vue'

export const returnProducts = (payload) => {
  const url = `${config.api.url}/api/ext/rma/return`
  // const cache = Vue.prototype.$db.usersCollection
  // const token = new Promise((resolve, reject) => {
  //   cache.getItem('current-token', (error, response) => {
  //     if (error) {
  //       reject(error)
  //     } else {
  //       resolve(response)
  //     }
  //   })
  // })
  // return token
  //   .then(bearerToken => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${bearerToken}`
    },
    body: JSON.stringify(payload)
  })
    .then(response => response.json())
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
    // })
}
