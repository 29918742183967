import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState, { PagedProductList } from '../../types/ProductState'
import { state } from 'core/modules/url/store/state'

const getters: GetterTree<ProductState, RootState> = {
  productParent: (state) => state.parent,
  productCurrent: (state) => state.current,
  currentConfiguration: (state) => state.current_configuration,
  productOriginal: (state) => state.original,
  currentOptions: (state) => state.current_options,
  breadcrumbs: (state) => state.breadcrumbs,
  productGallery: (state) => state.productGallery,
  items: state => state.list && (state.list as PagedProductList).items ? (state.list as PagedProductList).items : []
}

export default getters
