import { cartCacheHandlerFactory } from '../helpers/cartCacheHandler'
import { isSiteChanged } from '../../../../src/themes/zenith/utilities/sfccUtils'

export function afterRegistration({ Vue, config, store, isServer }) {
  if (!isServer) {
    const task = isSiteChanged()
      ? 'user/logout'
      : 'user/authorizeClient'
    Promise.all([store.dispatch(task)]).then(() => {
      store.dispatch('cart/load')
    })
  }
  store.subscribe(cartCacheHandlerFactory(Vue))
}
