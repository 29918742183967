import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
// const countries = require('@vue-storefront/i18n/resource/countries.json')
// let googleApi
// let mapLoader
// if (process.browser) {
//   mapLoader = require('google-maps')
//   mapLoader.KEY = config.google.maps.key
//   mapLoader.LIBRARIES = ['geometry']
//   mapLoader.VERSION = config.google.maps.version
//   googleApi = new Promise((resolve, reject) => {
//     mapLoader.load((google) => resolve(google))
//   })
// }
// export { googleApi }
export const getAkamaiPosition = () => {
  return new Promise((resolve, reject) => {
    const position = {}
    fetch(`${config.api.url}/api/ext/store-locator/akamai`)
    // fetch(`https://api-staging.zenith-watches.com/api/ext/store-locator/akamai`)
      .then(response => response.json())
      .then(({ lat, lng, country }) => {
        position.lat = +lat
        position.lng = +lng
        position.country = country
        resolve(position)
      })
  })
}

export const getCurrentPosition = () => {
  if (typeof navigator !== 'undefined' && navigator.geolocation) {
    return new Promise((resolve, reject) => {
      const position = {}
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        position.lat = coords.latitude
        position.lng = coords.longitude
        resolve(position)
      }, () => {
        fetch(`${config.api.url}/api/ext/store-locator/akamai`)
          .then(response => response.json())
          .then(({ lat, lng, country }) => {
            position.lat = +lat
            position.lng = +lng
            position.country = country
            resolve(position)
          })
      }, {
        timeout: 10000
      })
    })
  }
}

export const geocode = (position) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=${config.google.maps.key}`
  return fetch(url)
    .then(response => response.json())
    .then(json => {
      try {
        const countryCode = json.results
          .map(result => {
            return result.address_components
              .filter(component => {
                return component.types.includes('country')
              })
              .map(country => country.short_name)
          })[0][0]
        return countryCode
      } catch {
        return currentStoreView().storeCode && currentStoreView().storeCode.includes('_') ? currentStoreView().storeCode.split('_')[1].toUpperCase() : ''
      }
    })
}

export const computeDistance = (google, origin, destination) => {
  return new Promise((resolve, reject) => {
    const dm = new google.maps.DistanceMatrixService()
    dm.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING'
    }, response => {
      let result
      try {
        result = +response.rows[0].elements[0].distance.text.split(' ')[0].replace(',', '.')
        resolve(result)
      } catch {
        resolve(0)
      }
    })
  })
}

export const getItinerary = (position, store) => {
  return `https://www.google.com/maps/dir/?api=1&origin=${position.lat},${position.lng}&destination=${store.address.coords.lat},${store.address.coords.lng}`
}
