import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.SET_PAGE] (state, payload) {
    state.pages[`${payload.type}-${payload.uid}`] = payload.page
  },
  [types.CLEAR_PAGES] (state, payload) {
    Object.keys(state.pages).forEach((key) => {
      if(key.includes(payload.type)) {
        delete state.pages[key]
      }
    })
  },
  [types.SET_BLOCK] (state, payload) {
    state.blocks[payload.uid] = payload.block
  },
  [types.SET_TOTAL_RESULTS_SIZE] (state, payload) {
    state.totalResultsSize[`${payload.type}`] = payload.totalResultsSize
  },
  [types.SET_ALTERNATE_LANGUAGE] (state, payload) {
    state.alternateLanguages[`${payload.type}-${payload.uid}`] = payload.languages
  }
}
