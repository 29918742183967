import { helpers, minLength, required } from 'vuelidate/lib/validators'

export const getPasswordValidations = (context, passwordValidations, password) => {
  return [
    {
      condition: true,
      text: context.$t('Password must satisfy the following requirements:'),
      style: {color: 'black'}
    },
    {
      condition: true,
      text: context.$t('1 Upper case'),
      style: getConditionStyle(passwordValidations.upper, password)
    },
    {
      condition: true,
      text: context.$t('1 Lower case'),
      style: getConditionStyle(passwordValidations.lower, password)
    },
    {
      condition: true,
      text: context.$t('1 Digit'),
      style: getConditionStyle(passwordValidations.digit, password)
    },
    {
      condition: true,
      text: context.$t('1 Special character'),
      style: getConditionStyle(passwordValidations.special, password)
    },
    {
      condition: true,
      text: context.$t('Minimum length of 12 characters'),
      style: getConditionStyle(passwordValidations.length, password)
    }
  ]
}

const getConditionStyle = (condition, password) => {
  const color = !password ? 'var(--zenith-black)' : condition ? 'var(--zenith-valid)' : 'var(--zenith--error)'
  return {
    color,
    paddingLeft: '1rem'
  }
}

export const getPasswordValidators = () => {
  return {
    minLength: minLength(12),
    validPwd: helpers.regex('validPwd', /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{12,}$/),
    lower: helpers.regex('lower', /[a-z]/),
    upper: helpers.regex('upper', /[A-Z]/),
    digit: helpers.regex('digit', /\d/),
    special: helpers.regex('special', /[\W_]/),
    length: helpers.regex('length', /^.{12,}$/),
    required
  }
}
