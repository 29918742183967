import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'

export const getWatchClinicDates = payload => {
  const url = `${config.api.url}/api/ext/watch-clinic/calendar`
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(payload)
    },
    silent: true
  }).then(json => {
    if (json.result && json.result.message) {
      throw new Error(JSON.stringify(json))
    }
    return json.result
  })
}
