
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const isEcommerce = () => {
  const storeCode = currentStoreView().virtualStoreCode
  if (!storeCode || !config.storeViews[storeCode]) {
    // return a promise that resolves with false if storeCode is not found in config or there is no storeCode
    return new Promise((resolve, reject) => {
      resolve(false)
    })
  } else {
    const isEcommerceFlag = config.storeViews[storeCode].is_e_com

    // create a promise and resolve it with isEcommerceFlag value
    return new Promise((resolve, reject) => {
      resolve(isEcommerceFlag)
    })
  }
}
