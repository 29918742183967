import { beforeEach } from './router/beforeEach'
import { afterEach } from './router/afterEach'
import { createModule } from '@vue-storefront/core/lib/module'
import { initCacheStorage } from '@vue-storefront/core/helpers/initCacheStorage'

export const KEY = 'router-hook'
export const cacheStorage = initCacheStorage(KEY)
export const RouterHooks = createModule({
  key: KEY,
  router: { beforeEach, afterEach }
})
