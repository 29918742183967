import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const metaLinkBuilder = (route) => {
  const { url: currentUrl, storeCode } = currentStoreView()
  const routeName = route.name.replace(`${storeCode}-`, '')
  return {
    title: routeName.charAt(0).toUpperCase() + routeName.slice(1),
    link: [
      ...config.storeViews.mapStoreUrlsFor
        .filter(url => url !== 'int')
        .map((url) => ({
          vmid: 'hreflang-' + config.storeViews[url].url.split('_').map((x, i) => i === 1 ? x.toUpperCase() : x).join('-').replace('/', ''),
          rel: 'alternate',
          hreflang: config.storeViews[url].url.split('_').map((x, i) => i === 1 ? x.toUpperCase() : x).join('-').replace('/', ''),
          href: (config.client.url + config.storeViews[url].url + route.fullPath.replace(currentUrl, '')).replace(/\?page=1/, '')
        })),
      {
        vmid: 'hreflang',
        rel: 'alternate',
        hreflang: 'x-default',
        href: config.client.url + route.fullPath.replace(currentUrl, '/int').replace(/\?page=1/, '')
      }, {
        vmid: 'canonical',
        rel: 'canonical',
        href: (config.client.url + route.path + (Object.keys(route.query).length ? '?' + Object.keys(route.query).map(key => key + '=' + route.query[key]).join('&') : '')).replace(/\?page=1/, '')
      }
    ]
  }
}
