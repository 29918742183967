import { createModule } from '@vue-storefront/core/lib/module'
import { store } from './store'
// import config from 'config'

const KEY = 'watch-memory'

export const WatchMemory = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] }
})
