<template>
  <div>
    <template v-for="(component, i) in list">
      <template v-if="component && component.component && isComponentRenderingAllowed(i)">
        <component
          :is="component.component"
          :item="component.item"
          :items="component.items"
          :images="component.images"
          :products="component.products"
          :components="component.components"
          :declination="component.declination"
          :page-position="i"
          :key="'c' + i"
          :siblings-components="list"
          @hook:mounted="onComponentMounted(i)"
        ></component>
      </template>
    </template>
    <template v-if="!isProd">
      <template v-for="(component, i) in pre">
        <pre v-html="component" :key="'p' + i"></pre>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  props: {
    components: Array,
    isStrictRenderingOrder: {
      type: Boolean,
      default: false
    }
  },
  name: 'CmpRenderer',
  data() {
    return {
      mountedComponents: [],
      pre: []
    }
  },
  computed: {
    list() {
      return this.components.filter(this.exist)
    },
    isProd() {
      return process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    onComponentMounted(index) {
      if (this.isStrictRenderingOrder) {
        this.mountedComponents.push(index)

        if (index === this.list.length - 1) {
          this.$emit('mounted-completely')
        }
      }
    },
    isComponentRenderingAllowed(index) {
      return !this.isStrictRenderingOrder || (index === 0 || this.mountedComponents.includes(index - 1))
    },
    exist(c) {
      if (c && c.component && c.component in Vue.options.components) {
        return true
      } else {
        const strings = this.pre.map(c => JSON.stringify(c))
        if (c && c.component && !strings.includes(JSON.stringify(c))) {
          console.warn('[Component Renderer] Component', c.component, "doesn't exist")
          this.pre.push(c)
        } else if (!c || !c.component) {
          console.warn('[Component Renderer] Critical connector error', { component: c })
          this.pre.push({ message: 'Critical Error', component: c })
        }
        return false
      }
    }
  }
}
</script>
