import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import { once } from '@vue-storefront/core/helpers'
import config from 'config'

once('__VUE_EXTEND_I18N__', () => {
  Vue.use(VueI18n)
})
 
const loadedLanguages = ['default']
  // CORE OVERRIDE: use virtual locale instead of default locale when possible
  const i18n = new VueI18n({
  locale: config.i18n.bundleAllStoreviewLanguages ? config.i18n && config.i18n.defaultLocale ? config.i18n.defaultLocale : 'en-ge' : 'en-ge', // set locale
  fallbackLocale: 'default',
  messages: config.i18n.bundleAllStoreviewLanguages ? require('./resource/i18n/multistoreLanguages.json') : {
    'default': require('./resource/i18n/default.json')
  },
  silentTranslationWarn: true
})

function setI18nLanguage (lang: string): string {
  i18n.locale = lang
  return lang
}

/**
 * Lazy load date locales file for current switched language.
 */
// const loadDateLocales = async (lang: string = 'en'): Promise<void> => {
//   let localeCode = lang.toLocaleLowerCase()
//   try { // try to load full locale name
//     // await import(/* webpackChunkName: "dayjs-locales" */ `dayjs/locale/${localeCode}`)
//     await import(/* webpackChunkName: "dayjs-locales-[request]" */ `dayjs/locale/${localeCode}`)
//   } catch (e) { // load simplified locale name, example: de-DE -> de
//     const separatorIndex = localeCode.indexOf('-')
//     if (separatorIndex) {
//       localeCode = separatorIndex ? localeCode.substr(0, separatorIndex) : localeCode
//       // await import(/* webpackChunkName: "dayjs-locales" */ `dayjs/locale/${localeCode}`)
//       await import(/* webpackChunkName: "dayjs-locales-[request]" */ `dayjs/locale/${localeCode}`)
//     }
//   }
// }

export async function loadLanguageAsync (lang: string): Promise<string> {
  // await loadDateLocales(lang)
  if (!config.i18n.bundleAllStoreviewLanguages) {
    if (i18n.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        try {
          const msgs = await import(/* webpackChunkName: "lang-[request]" */ `./resource/i18n/${lang}.json`)
          i18n.setLocaleMessage(lang, msgs.default)
          loadedLanguages.push(lang)
          return setI18nLanguage(lang)
        } catch (e) { // eslint-disable-line handle-callback-err
          Logger.debug('Unable to load translation', e)()
          return ''
        }
      }
      return setI18nLanguage(lang)
    }
  } else {
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  }
  return lang
}

// loadLanguageAsync(config.i18n.defaultLocale)

export default i18n
