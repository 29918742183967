import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export function productQueryParameters({
  searchText,
  start,
  size,
  additionalFilters = []
}) {
  const storeCode = currentStoreView().storeCode

  const query = new SearchQuery()
  query.setSearchText(searchText)
  query
    .applyFilter({ key: 'visibility', value: { in: [2, 3, 4] } })
    .applyFilter({ key: 'status', value: { in: [1] } })

  for (const filter of additionalFilters) {
    query.applyFilter(filter)
  }

  const params = {
    query: query,
    start,
    size,
    entityType: 'product',
    sort: '',
    storeCode,
    excludeFields: null,
    includeFields: null
  }

  return params
}
