export { getRouteName } from './get-route-name'
export { isEcommerce } from './is-ecommerce'
export { storeLocator } from './store-locator'
export { search } from './search'
export { suggest } from './suggest'
export {
  getAkamaiPosition,
  getCurrentPosition,
  geocode,
  computeDistance,
  getItinerary
} from './google-map-api'
export { orderCertificate } from './order-certificate'
export { returnProducts } from './rma'
export { newsletter } from './newsletter'
export { activateAccount } from './activate-account'
export { parsePhoneNumber } from './phone-code'
export { metaLinkBuilder } from './meta-buidler'
export { getProductFromPan } from './get-product-from-pan'
export { add as registerWatch } from './warranty'
export { downloadForm } from './download-form'
// export { chronomasterNow } from './chronomaster-timing'
export { add as addStory, get as getStory, all as getStories } from './stories'
export { CustomProductBundleItem } from './product-bundle'
export { STRAP_SIZE_KEY, BUCKLE_KEY } from './straps'
export { getProductPrice } from './get-product-price'
export { addScriptInPage } from './add-script-in-page'
export { getWatchClinicDates } from './watch-clinic-available-dates'
export { DISABLED_URLS_STORES } from './disabled-urls'
export { getPasswordValidations, getPasswordValidators } from './password-validation'
