import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.ADD_ITEM] (state, payload) {
    state.products.push(payload)
  },
  [types.REMOVE_ITEM] (state, payload) {
    state.products = state.products.filter(({ sku }) => sku !== payload.sku)
  },
  [types.CLEAR_WISHLIST] (state, payload) {
    state.products = []
  },
  [types.SET_WISHLIST] (state, payload) {
    state.products = payload
  }
}
