import Vue from 'vue'

const DWCookies = [
  'dwsid',
  'dwanonymous',
  'dwsecuretoken',
  'dw_dnt',
  'sid'
]

export const clearLocalCart = () => {
  localStorage.removeItem('shop/carts/current-cart')
}

export const deleteDWCookies = () => {
  Vue.cookies.keys().forEach(cookie => {
    DWCookies.forEach(DWCookie => {
      if (cookie.includes(DWCookie)) {
        Vue.cookies.remove(cookie)
      }
    })
  })
}
