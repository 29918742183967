import { PrismicState } from '../types/PrismicState'
import { GetterTree } from 'vuex'

/*
* Queried content is stored in an object to avoid doube query while hydration is running
*/
export const getters: GetterTree<PrismicState, any> = {
  getPage: (state) => ({ type, uid }) => state.pages[`${type}-${uid}`],
  getAlternateLanguages: (state) => ({ type, uid }) => state.alternateLanguages[`${type}-${uid}`],
  getPages: (state) => ({ type }) => Object
    .entries(state.pages)
    .filter(([key, value]) => key.includes(type))
    .map(([key, value]) => ({ ...value, uid: key.replace(`${type}-`, '') })),
  getTotalResultsSize: (state) => ({ type }) => Object
    .entries(state.totalResultsSize)
    .filter(([key, value]) => key.includes(type))
    .map(([key, value]) => value)
    .find(value => value !== null),
  getBlock: (state) => (uid) => state.blocks[uid],
  getBlocks: (state) => state.blocks
}
