import { Module } from 'vuex'
import { KonamiState } from '../types/KonamiState'
import { getters } from './getters'
import { mutations } from './mutations'
import { actions } from './actions'
import { state } from './state'

export const module: Module<KonamiState, any> = {
  namespaced: true,
  actions,
  mutations,
  getters,
  state
}
