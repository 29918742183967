import { SearchState } from '../types/SearchState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import { productQueryParameters } from '../queries'
import rootStore from '@vue-storefront/core/store'
import { storeLocator } from '../../../themes/zenith/utilities'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'


export const actions: ActionTree<SearchState, any> = {
  search({ commit }, { searchText, productOnly = false, size = 1000, additionalFilters = [], customScoreConfig = null }) {
    // replace diacritics because some API doesn't work well with accents
    searchText = searchText.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const { url } = currentStoreView()
    const products = quickSearchByQuery(productQueryParameters({ searchText, start: 0, size: size, additionalFilters }), customScoreConfig)

    commit(types.SET_LOADING, true)

    if (!productOnly) {
      const faq = rootStore.dispatch('prismic/search', { type: 'faq', searchText })
      const news = rootStore.dispatch('prismic/search', { type: 'news', searchText })
      const brand = rootStore.dispatch('prismic/search', { type: 'brand', searchText })
      const service = rootStore.dispatch('prismic/search', { type: 'service', searchText })
      const collections = rootStore.dispatch('prismic/search', { type: 'collection', searchText })
      const stores = storeLocator({ country: null, address: searchText })
      return Promise.all([products, faq, news, brand, service, collections, stores])
        .then(([products, faq, news, brand, service, collections, stores]) => {
          collections = collections.map(({ type, uid, data }) => ({
            routeParam: uid,
            type,
            title: data.title,
            description: data.description,
            image: data.image
          }))
          news = news.map(({ type, uid, data }) => ({
            routeParam: uid,
            type,
            title: data.title,
            excerpt: data.excerpt && data.excerpt.length ? data.excerpt[0].text : null,
            image: data.image
          }))
          faq = faq.map(({ type, uid, data }) => ({
            routeParam: uid,
            type,
            categories: data.content
              .map(({ primary, items }) => ({
                category: primary.block_title,
                items: items
                  .map(({ block_answer, block_question }) => ({ question: block_question, answer: block_answer }))
                  .filter(({ answer, question }) => answer.toLowerCase().includes(searchText.toLowerCase()) || question.toLowerCase().includes(searchText.toLowerCase()))
              }))

          }))
          service = service.map(({ type, uid, data }) => ({
            routeParam: uid,
            type,
            title: data.title,
            description: data.description
          }))
          brand = brand.map(({ type, uid, data }) => ({
            routeParam: uid,
            type,
            title: data.title,
            description: data.description
          }))
          products = products.items.map((raw) => {
            const { url_key, secondary_image, price, name, image, sku } = raw
            return {
              routeParam: url_key,
              name,
              price: price,
              image,
              imageHover: secondary_image,
              sku,
              raw
            }
          }) as any
          stores = stores.map(store => {
            let normalizedStoreName = store.name ? store.name : ''
            normalizedStoreName = normalizedStoreName.normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/[^a-z-A-Z ]/g, '')
              .replace(/ +/, ' ')
              .replace(/\s/g, '-')
              .toLowerCase()
            return { ...store, queryParam: `?filter=official,service,retailer&store=${normalizedStoreName}&country=${store.address.countryId}&address=${searchText}&latlng=${store.address.coords.lat},${store.address.coords.lng}` }
          })
          commit(types.SET_RESULTS, { collections, news, faq, brand, service, products, stores })
          commit(types.SET_LOADING, false)
        })
    } else {
      return Promise.all([products])
        .then(([products]) => {
          products = products.items.map((raw) => {
            const { url_key, secondary_image, price, name, image, sku } = raw
            return {
              routeParam: url_key,
              name,
              price: price,
              image,
              imageHover: secondary_image,
              sku,
              raw
            }
          }) as any
          commit(types.SET_RESULTS, { products })
          commit(types.SET_LOADING, false)
        })
    }
  }
}
