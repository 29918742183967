import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const newsletter = ({ email, civility, firstname, lastname, country }) => {
  const { storeCode } = currentStoreView()
  const url = `${config.api.url}/api/ext/newsletter/subscribe`
  const body = {
    email,
    civility: civility || 'unknow',
    firstname: firstname || 'unkown',
    lastname: lastname || 'unknown',
    country: country || 'unknown',
    storeCode
  }
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(json => {
      if ((json.result && json.result.message)) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}
