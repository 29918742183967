import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'

export const orderCertificate = (formData) => {
  const url = `${config.api.url}/api/ext/order-certificate/upload-files?token={{token}}&cartId={{cartId}}`

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      body: formData
    },
    silent: true
  })
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      }
      return json.result
    })
}
