import config from 'config'
import { isServer } from '@vue-storefront/core/helpers'
import * as url from 'url'

export default class ImageManager {
  static convert(uri, media = 'image', type = 'default', debug = false) {
    if (typeof uri === 'undefined') return ''
    if (type === 'static') return uri
    if (config[media + 'Manager'].active) {
      if (debug) console.log('[ImageManager]', uri, media, type)
      return `${config[media + 'Manager'][type].protocol}://${config[media + 'Manager'][type].host}${ImageManager.getUrl(uri, media, type, debug).pathname}`
    } else return uri
  }
  static getUrl(uri, media, type, debug = false) {
    try {
      if (uri.indexOf('://') > 0 || uri.indexOf('//') === 0) {
        if (debug) console.log('[ImageManager]', 'Absolute URL ', uri)
      } else {
        uri = `${config[media + 'Manager'][type].protocol}://${config[media + 'Manager'][type].host}${config[media + 'Manager'][type].fallbackPath}${uri}`
        if (debug) console.log('[ImageManager]', 'Relative URL ', uri.indexOf('://'), uri.indexOf('//'))
      }
      if (isServer) {
        const URL = url.URL
        return new URL(uri)
      } else return new URL(uri)
    } catch (error) {
      console.error(error)
      return {
        'pathname': '/zenith-website-dev/4fa69688-cce0-440a-95d4-f5b42a0695be_logo-zenith-3d.jpg'
      }
    }
  }
  static getOriginal(url) {
    return url
  }
  static getLowDefinition(url, width, aspectRatio = 0.5625, preserve = false, blur = 10, forceJpg = false) {
    // return `${url}?impolicy=resize&w=${width}&h=${Math.round(width * aspectRatio)}&q=1`
    return (preserve) ? `${url}?im=Resize=${width};Blur=${blur}&q=1` : `${url}?im=Resize=(${width},${Math.trunc(width * aspectRatio)}),aspect=fill;Crop=(0,0,${width},${Math.trunc(width * aspectRatio)}),gravity=Center;Blur=${blur}&q=1`
  }
  static getSrcSet(url, from, to, gap, aspectRatio = 0.5625, preserve = false) {
    let srcset = ''
    while (from >= to) {
      if (!preserve) srcset += `${url}?im=Resize=(${from},${Math.trunc(from * aspectRatio)}),aspect=fill;Crop=(0,0,${from},${Math.trunc(from * aspectRatio)}),gravity=Center ${from}w, `
      else srcset += `${url}?im=Resize=${from} ${from}w, `
      from -= gap
    }
    return srcset
  }
}
