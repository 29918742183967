import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.SHOW_FEEDBACK](state, { message, context, show, duration }) {
    state.context = context
    state.message = message
    state.duration = duration
    state.show = show
    if (show) {
      setTimeout(() => {
        state.message = ''
        state.context = 'info'
        state.show = false
      }, duration)
    }
  },
}
