<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import rootStore from '@vue-storefront/core/store'
import { main } from './utilities/abtasty-fn'
import { getProductPrice } from './utilities';
import { getPriceWithCurrency } from './'

// Force chucnk into app bundle
const LazyImage = require('./components/utils/LazyImage')
const NoSSR = require('vue-no-ssr')

const DefaultLayout = () =>
  import(/* webpackChunkName: "vsf-layout-default" */ "./layouts/Default");
export default {
  components: {
    LazyImage,
    NoSSR,
    DefaultLayout
  },
  data () {
    return {
      previousPath: null
    }
  },
  computed: {
    ...mapState({
      overlayActive: (state) => state.ui.overlay,
    }),
    layout () {
      return `${"default"}-layout`;
    },
  },
  methods: {
    ...mapActions('cart', ['load', 'sync', 'serverPull']),
    ...mapActions("user", ["refresh", "me"]),
    afterEnter () {
      this.previousPath = this.$route.path;
    },
  },
  created () {
    Promise.all([
      this.$store.dispatch('zenith-ui/isEcommerce')
    ])
  },
  mounted () {
    if (typeof window !== 'undefined') {
      window.syncPwaCart = () => {
        console.log('[PWA][ABTASTY-MINICART] Cart synchronization started')
        return new Promise((resolve) => {
          return this.load({ forceClientState: false, dryRun: false, forceServerState: true })
            .then(() => {
              console.log('[PWA][ABTASTY-MINICART] Cart synchronization sucess')
              resolve(true)
            })
            .catch(() => {
              console.error('[PWA][ABTASTY-MINICART] Cart synchronization failed in promise')
              resolve(false)
            })
        })
      }
      window.formatProductPrice = (item) => {
        return getPriceWithCurrency(getProductPrice(item))
      }
    } else {
      console.log('[PWA][ABTASTY-MINICART] Cart synchronization function couldn\'t be attached to window object at runtime')
    }
  },

};
</script>

<style lang="scss" scoped>
#app {
  background: var(--zenith-app-background);

  &>div {
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
</style>
