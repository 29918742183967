import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'

import { ActionTree } from 'vuex'

import { ProductsState } from '../types/ProductsState'
import * as types from './mutation-types'

import {
  productsQuery,
  productQuery
} from '../queries'

export const actions: ActionTree<ProductsState, any> = {
  async setProducts({ commit }, { start, size }) {
    const { storeCode } = currentStoreView()
    start = start || 0
    size = size || 500
    return quickSearchByQuery({ query: productsQuery, storeCode, start, size })
      .then((response) => commit(types.SET_PRODUCTS, response.items))
  },
  async setProduct({ commit }, { sku }) {
    const { storeCode } = currentStoreView()
    return quickSearchByQuery({ query: productQuery({ sku }), storeCode })
      .then((response) => commit(types.SET_PRODUCT, response.items[0]))
  },
}
