import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const search = async (searchText, start, size) => {
  if (searchText.length < 3) {
    return null
  }
  const storeCode = currentStoreView().storeCode

  const productQuery = new SearchQuery()
  productQuery.setSearchText(searchText)

  const productQueryParameters = {
    query: productQuery,
    start,
    size,
    entityType: 'product',
    sort: '',
    storeCode,
    excludeFields: null,
    includeFields: null
  }

  const categoryQuery = new SearchQuery()
  categoryQuery.setSearchText(searchText)

  const categoryQueryParameters = {
    query: categoryQuery,
    start,
    size,
    entityType: 'category',
    sort: '',
    storeCode,
    excludeFields: null,
    includeFields: null
  }

  return Promise.all([
    quickSearchByQuery(productQueryParameters)
      .then(response => ({ products: response })),
    quickSearchByQuery(categoryQueryParameters)
      .then(response => ({ categories: response }))
  ])
    .then(responses => responses.reduce((acc, curr) => {
      acc[Object.keys(curr)[0]] = Object.values(curr)[0]
      return acc
    }, {}))
}
