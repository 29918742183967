import { WishlistState } from '../types/WishlistState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'

import {
  productQuery
} from '../../zenith-products/queries'

export const actions: ActionTree<WishlistState, any> = {
  isInWishlist(sku) {

  },
  clear({ commit }) {
    commit(types.CLEAR_WISHLIST)
  },
  async addItem({ commit }, sku) {
    return new Promise((resolve, reject) => {
      return TaskQueue.execute({
        url: `${config.api.url}/api/ext/wishlist/add?token={{token}}` + '',
        payload: {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sku: encodeURIComponent(sku)
          })
        }
      })
        .then(resp => {
          commit(types.ADD_ITEM, resp.result)
          return resolve(resp.result)
        })
        .catch(() => {
          throw new Error('[User] Add item to wishlist failed')
        })
    })
  },
  async removeItem({ commit }, sku) {
    return new Promise((resolve, reject) => {
      return TaskQueue.execute({
        url: `${config.api.url}/api/ext/wishlist/remove?token={{token}}` + '',
        payload: {
          method: 'DELETE',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            sku: encodeURIComponent(sku)
          })
        }
      })
        .then(resp => {
          commit(types.REMOVE_ITEM, resp.result)
          return resolve(resp.result.body.items)
        })
        .catch(() => {
          throw new Error('[User] Remove item from wishlist failed')
        })
    })
  },
  async loadWishlist({ commit }) {
    return new Promise((resolve, reject) => {
      // call get endpoint on magento
      return TaskQueue.execute({
        url: `${config.api.url}/api/ext/wishlist/get?token={{token}}` + '',
        payload: {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
          }
        }
      })
        .then(resp => {
          let products = resp.result.body.items
          const { storeCode } = currentStoreView()
          const promises = products.map(item => {
            return quickSearchByQuery({ query: productQuery({ sku: item.product_sku }), storeCode })
              .then((response) => response.items[0])
          })

          Promise.all(promises).then(items => {
            products = items
            commit(types.SET_WISHLIST, items.map(item => {
              item['raw'] = item
              return item
            }))
            return resolve(items)
          })

        })
        .catch(() => {
          throw new Error('[User] Load wishlist failed')
        })
    })
  }

}
