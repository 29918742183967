import { SearchState } from '../types/SearchState'

export const state: SearchState = {
  results: {
    news: [],
    faq: [],
    brand: [],
    service: [],
    collections: [],
    stores: [],
    products: []
  },
  loading: false
}
