// Read more about modules: https://github.com/DivanteLtd/vue-storefront/blob/master/doc/api-modules/about-modules.md
import { module } from '../zenith-products/store'
import { createModule } from '@vue-storefront/core/lib/module'
import { initCacheStorage } from '@vue-storefront/core/helpers/initCacheStorage'

export const KEY = 'zenith-collections'
export const cacheStorage = initCacheStorage(KEY)
export const Collections = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module }] },
})
