import { Route } from 'vue-router'
import { Logger } from '@vue-storefront/core/lib/logger'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import rootStore from '@vue-storefront/core/store'
import Vue from 'vue'
import config from 'config'
import { sha3_256 } from 'js-sha3'
import { isServer } from '@vue-storefront/core/helpers'

const switchCart = (user) => {
  Vue.prototype.$db.cartsCollection.clear()
    .then(() => rootStore.dispatch('cart/disconnect'))
    .then((user) => {
      if (user) {
        rootStore.dispatch('cart/connect', { guestCart: false, forceClientState: false })
      } else {
        rootStore.dispatch('cart/clear', {}, { root: true })
      }
    })
}

export function beforeEach(to: Route, from: Route, next) {
  if (to.path && !to.path.includes('/checkout') && to.path !== from.path) {
    rootStore.dispatch('cart/forcecartalign')
  }
  // Cart reset token on store code change
  const { url, storeCode, virtualStoreCode } = currentStoreView()
  Vue.prototype.$db.usersCollection.getItem('current-user')
    .then((user) => {
      if (typeof window !== 'undefined' && localStorage) {
        const sc = localStorage.getItem('storeCode')
        if (sc !== virtualStoreCode) {
          localStorage.setItem('storeCode', virtualStoreCode)
          switchCart(user)
        }
      }
    })
  const path = to.path.replace(url, '')
  const isFirstRouting = !from || !from.name
  Logger.info(`[Router] Route ${to.path} is going to be visited on store ${storeCode}.`)()
  if (path === '/checkout' && isFirstRouting) {
    next({ path: url + '/cart' })
  } else {
    next()
  }

  // GTM
  const { i18n } = currentStoreView()
  const { fullLanguageName, currencyCode, fullCountryName } = i18n
  if (config.googleTagManager.id) {

    if (!isServer) {
      const user = JSON.parse(localStorage.getItem('shop/user/current-user'))
      const orders = JSON.parse(localStorage.getItem('shop/ordersHistory/orders-history'))
      if (user) {
        Vue.prototype.gtm.trackEvent({
          event: 'virtualPageview',
          page: to.path,
          clientStatus: orders && orders.items.length ? 'Client' : 'Not Client',
          currencyCode: currencyCode,
          country: fullCountryName,
          hashedEmail: sha3_256(user.email),
          language: fullLanguageName,
          loginStatus: 'Logged',
          pageMidCategory: to.meta.pageMidCategory,
          pageSubCategory: to.meta.pageSubCategory,
          pageTopCategory: to.meta.pageTopCategory && to.meta.pageTopCategory.length ? to.meta.pageTopCategory : to.params.uid || to.params.slug,
          pageType: to.meta.pageType,
          userId: user.id
        })
      } else {
        Vue.prototype.gtm.trackEvent({
          event: 'virtualPageview',
          page: to.path,
          clientStatus: orders && orders.items.length ? 'Client' : 'Not Client',
          currencyCode: currencyCode,
          country: fullCountryName,
          language: fullLanguageName,
          loginStatus: 'Not Logged',
          pageMidCategory: to.meta.pageMidCategory,
          pageSubCategory: to.meta.pageSubCategory,
          pageTopCategory: to.meta.pageTopCategory,
          pageType: to.meta.pageType,
        })
      }
    }
  }
}
