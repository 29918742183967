<template>
  <div class="mb15 mt20 payment-cash-on-delivery-info-container">
    <h4 class="mt0">
      Payment Information
    </h4>
    <div class="bg-cl-secondary px20 py20">
      <p>
        You are going to pay for this order upon delivery.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaymentCashOnDelivery',
  data () {
    return {}
  }
}
</script>
