import { KonamiState } from '../types/KonamiState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'

export const actions: ActionTree<KonamiState, any> = {
  async setCode({ commit }, { code }) {
    commit(types.SET_CODE, { code })
  },
  async setOverlay({ commit }, { overlay }) {
    commit(types.SET_OVERLAY, { overlay })
  },
}
