import config from 'config'
import Vue from 'vue'

const getSfccSiteId = storeCode => {
  let site = config.SFCCSites.filter(
    site => site.allowedLocales.indexOf(storeCode) > -1
  )
  return site[0] ? site[0].siteId : ''
}

const isSiteChanged = () => {
  const currentSite = Vue.prototype.$cookies.get(`site_id`) || ''
  const newSite = Vue.prototype.$db.currentStoreCode
  const newSiteId = getSfccSiteId(newSite)
  return currentSite !== newSiteId
}

export { getSfccSiteId, isSiteChanged }
