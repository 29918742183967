import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import config from 'config'
import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'

declare global {
  interface Window { grecaptcha: any; }
}

let isRecaptachaLoaded = false;

const loadRecaptcha = (isRecaptachaLoaded: boolean) => {
  if (!isRecaptachaLoaded) {
    // This function will be fired both on server and client side context after registering other parts of the module
    if (!config.googleRecaptcha || !config.googleRecaptcha.is_active || !config.googleRecaptcha.tokens) {
      if (!config.googleRecaptcha.is_active) {
        Logger.warn('Google captcha module is disabled.', 'Google Recaptcha')();
        return;
      }
      if (!config.googleRecaptcha.tokens || !config.googleRecaptcha.tokens.site_key) {
        Logger.warn('No google captcha config or captcha site_key found.', 'Google Recaptcha')();
        return;
      }
    }
    if (!isServer) {
      var head = document.head;
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = false;
      script.defer = true;
      script.src = '//www.google.com/recaptcha/api.js?render=' + config.googleRecaptcha.tokens.site_key;
      head.appendChild(script);
      isRecaptachaLoaded = true;
      return new Promise((resolve, reject) => {
        script.onload = () => resolve(window.grecaptcha = window.grecaptcha || {});
      })
    }
  }

}
// actions
const actions: ActionTree<any, RootState> = {
  /**
   * Check is verified google captcha
   */
  async isVerifiedCaptcha({ commit, dispatch, state }) {
    if (!isServer) {
      await loadRecaptcha(isRecaptachaLoaded).then(() => {
        return new Promise(resolve => {
          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(config.googleRecaptcha.tokens.site_key)
              .then(gToken => {
                commit('SET_CAPTCHA_TOKEN', gToken)
                dispatch('verifiedCaptcha', gToken).then(resolve)
              })
          })
        }).then(() => state.is_verified)
      })
    }
    return true
  },
  /**
   * Verify captcha with the google api
   * @param param0
   * @param gToken
 */
  async verifiedCaptcha({ commit, state }, gToken) {
    // await commit('USER_VERIFIED_CAPTCHA', true)
    let gCaptchaQueryBody = JSON.stringify({
      response: gToken
    })

    await fetch(config.api.url + config.googleRecaptcha.endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: gCaptchaQueryBody
    })
      .then(resp => {
        return resp.json()
      })
      .then(resp => {
        var gCaptchaResponse = false
        if (resp.code === 200) {
          gCaptchaResponse = resp.result.success
          // console.log('Response of request', resp, gCaptchaResponse)
        }
        commit('USER_VERIFIED_CAPTCHA', gCaptchaResponse)
        return gCaptchaResponse
      })
  },

}

export default actions
