import rootStore from '@vue-storefront/core/store'

export function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer) {
    // const keys = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]
    // const keys = [68, 65, 82, 75, 77, 79, 68, 69, 79, 78]
    const keys = [68, 65, 82, 75, 77, 79, 68, 69]
    const isChecked = {
      0: false,
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false
      // 8: false,
      // 9: false
    }
    let current = 0
    window.addEventListener('keyup', (e) => {
      const key = e.which || e.keyCode || e.detail
      // console.log('[ZenithKonamiCode] key ', key)
      if (keys.includes(key)) {
        if (key === keys[current]) {
          current += 1
          isChecked[current - 1] = true
          if (current === keys.length) {
            // console.log('[ZenithKonamiCode] congrats for the code')
            config.darkmode = true
            rootStore.dispatch('darkmode/activate')
            current = 0
            Object.keys(isChecked).forEach(k => {
              isChecked[k] = false
            })
          }
        } else {
          Object.keys(isChecked).forEach(k => {
            isChecked[k] = false
          })
          current = 0
        }
      }
    })
  }
}
