import { currentStoreView } from '@vue-storefront/core/lib/multistore'
// import Vue from 'vue'
import rootStore from '@vue-storefront/core/store'

// import Search from 'theme/pages/Search.vue'
// import PLP from 'theme/pages/PLP.vue'
// import StoreLocator from 'theme/pages/StoreLocator.vue'
// import MyProfile from 'theme/pages/MyProfile.vue'
// import { chronomasterNow } from '../utilities'
// import Newsletter from 'theme/pages/Newsletter.vue'

const Home = () => import(/* webpackChunkName: "home" */ 'theme/pages/Home.vue')
const Search = () => import(/* webpackChunkName: "search-page" */ 'theme/pages/Search.vue')

const PageNotFound = () => import(/* webpackChunkName: "page-not-found" */ 'theme/pages/PageNotFound.vue')
const PageError = () => import(/* webpackChunkName: "error" */ 'theme/pages/PageError.vue')
const Preview = () => import(/* webpackChunkName: "preview" */ 'theme/pages/Preview.vue')

const AccountCreation = () => import(/* webpackChunkName: "account-creation" */ 'theme/pages/AccountCreation.vue')
const AccountConfirmation = () => import(/* webpackChunkName: "account-confirmation" */ 'theme/pages/AccountConfirmation.vue')
const EmailConfirmation = () => import(/* webpackChunkName: "email-confirmation" */ 'theme/pages/EmailConfirmation.vue')
const Login = () => import(/* webpackChunkName: "login" */ 'theme/pages/Login.vue')
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ 'theme/pages/ForgotPassword.vue')
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */ 'theme/pages/ResetPassword.vue')
const ChangePassword = () => import(/* webpackChunkName: "change-password" */ 'theme/pages/ChangePassword.vue')
const Contact = () => import(/* webpackChunkName: "contact" */ 'theme/pages/Contact.vue')

const Collection = () => import(/* webpackChunkName: "collection" */ 'theme/pages/Collection.vue')
const CollectionLanding = () => import(/* webpackChunkName: "collection-landing" */ 'theme/pages/CollectionLanding.vue')
const DefyExtreme = () => import(/* webpackChunkName: "collection-defy-extreme" */ 'theme/pages/DefyExtreme.vue')
// const Products = () => import(/* webpackChunkName: "products" */ 'theme/pages/Products.vue')
const ProductDetails = () => import(/* webpackChunkName: "product-details" */ 'theme/pages/ProductDetails.vue')
const PLP = () => import(/* webpackChunkName: "plp" */ 'theme/pages/PLP.vue')

const Cart = () => import(/* webpackChunkName: "cart" */ 'theme/pages/Cart.vue')
const Checkout = () => import(/* webpackChunkName: "checkout" */ 'theme/pages/Checkout.vue')
const MyProfile = () => import(/* webpackChunkName: "my-profile" */ 'theme/pages/MyProfile.vue')

const LoginOrGuest = () => import(/* webpackChunkName: "login-or-guest" */ 'theme/pages/LoginOrGuest.vue')

const StoreLocator = () => import(/* webpackChunkName: "store-locator" */ 'theme/pages/StoreLocator.vue')

const News = () => import(/* webpackChunkName: "news-detail" */ 'theme/pages/News.vue')
// const NewsList = () => import(/* webpackChunkName: "news-list" */ 'theme/pages/NewsList.vue')
const Newsletter = () => import(/* webpackChunkName: "newsletter" */ 'theme/pages/Newsletter.vue')

const Brand = () => import(/* webpackChunkName: "brand" */ 'theme/pages/Brand.vue')
const Service = () => import(/* webpackChunkName: "service" */ 'theme/pages/Service.vue')
const Selection = () => import(/* webpackChunkName: "selection" */ 'theme/pages/Selection.vue')
const SimplePage = () => import(/* webpackChunkName: "page" */ 'theme/pages/SimplePage.vue')
const Livetour = () => import(/* webpackChunkName: "livetour" */ 'theme/pages/Livetour.vue')

const FAQ = () => import(/* webpackChunkName: "faq" */ 'theme/pages/FAQ.vue')
const RMA = () => import(/* webpackChunkName: "rma" */ 'theme/pages/RMA.vue')
const OrderCertificate = () => import(/* webpackChunkName: "order-certificate" */ 'theme/pages/OrderCertificate.vue')
const Order = () => import(/* webpackChunkName: "order" */ 'theme/pages/Order.vue')
const Register = () => import(/* webpackChunkName: "register" */ 'theme/pages/Register.vue')
const WarrantyExtensionSuccess = () => import(/* webpackChunkName: "warranty-extension-success" */ 'theme/pages/WarrantyExtensionSuccess.vue')
const WarrantyExtensionError = () => import(/* webpackChunkName: "warranty-extension-error" */ 'theme/pages/WarrantyExtensionError.vue')

const Dispatch = () => import(/* webpackChunkName: "dispatch" */ 'theme/pages/Dispatch.vue')
const Sitemap = () => import(/* webpackChunkName: "sitemap" */ 'theme/pages/Sitemap.vue')
const SitemapStore = () => import(/* webpackChunkName: "sitemap-store" */ 'theme/pages/SitemapStore.vue')

const PantoneLanding = () => import(/* webpackChunkName: "pantone-landing" */ 'theme/pages/PantoneLanding.vue')
const Stories = () => import(/* webpackChunkName: "stories" */ 'theme/pages/Stories.vue')
const StoryCreation = () => import(/* webpackChunkName: "story-creation" */ 'theme/pages/StoryCreation.vue')

const Defy5hz = () => import(/* webpackChunkName: "defy-5hz" */ 'theme/pages/Defy5hz.vue')
const TestComponents = () => import(/* webpackChunkName: "defy-5hz" */ 'theme/pages/TestComponents.vue')

const Commitment = () => import(/* webpackChunkName: "commitment" */ 'theme/pages/Commitment.vue')
const TheMaison = () => import(/* webpackChunkName: "defy-5hz" */ 'theme/pages/TheMaison.vue')
const TimeToReachYourStar = () => import(/* webpackChunkName: "time-to-reach-your-star" */ 'theme/pages/TimeToReachYourStar.vue')
const NewsLanding = () => import(/* webpackChunkName: "news-landing" */ 'theme/pages/NewsLanding.vue')

let routes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    alias: '/pwa.html',
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Home',
      pageType: 'Home'
    }
  },
  {
    name: 'error',
    path: '/error',
    component: PageError,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Error 500',
      pageType: 'Error'
    }
  },
  {
    name: 'page-not-found',
    path: '/page-not-found',
    component: PageNotFound,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Error 404',
      pageType: 'Error'
    }
  },
  {
    name: 'preview',
    path: '/preview',
    component: Preview,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'account-creation',
    path: '/account-creation',
    component: AccountCreation,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Account Creation',
      pageType: 'Account'
    }
  },
  {
    name: 'account-confirmation',
    path: '/account-confirmation',
    component: AccountConfirmation,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Account Confirmation',
      pageType: 'Account'
    }
  },
  {
    name: 'email-confirmation',
    path: '/email-confirmation',
    component: EmailConfirmation,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Email Confirmation',
      pageType: 'Account'
    }
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const { account, storeCode } = currentStoreView()
      if (account === false && storeCode !== 'zh_cn') {
        next('page-not-found')
      } else {
        next()
      }
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Login',
      pageType: 'Account'
    }
  },
  {
    name: 'forgot-password',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Forgot Password',
      pageType: 'Account'
    }
  },
  {
    name: 'reset-password',
    path: '/reset-password',
    component: ResetPassword,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Reset Password',
      pageType: 'Account'
    }
  },
  {
    name: 'change-password',
    path: '/change-password',
    component: ChangePassword,
    beforeEnter: (to, from, next) => {
      let { url } = currentStoreView()
      url = url + '/' || '/'
      const isUserLoggedIn = rootStore.getters['user/isLoggedIn']
      if (isUserLoggedIn) next()
      else next(url + 'login')
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Change password',
      pageType: 'Account'
    }
  },
  {
    name: 'register',
    path: '/register',
    component: Register,
    beforeEnter: (to, from, next) => {
      let { url } = currentStoreView()
      url = url + '/' || '/'
      const isUserLoggedIn = rootStore.getters['user/isLoggedIn']
      if (isUserLoggedIn) next()
      else next(url + 'login')
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Register',
      pageType: 'Account'
    }
  },
  {
    name: 'collection',
    path: '/collection/:uid',
    component: Collection,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Collection'
    }
  },
  {
    name: 'collection-landing-chronomaster',
    path: '/chronomaster-sport',
    component: CollectionLanding,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'ChronomasterSport'
    }
  },
  {
    name: 'defy-extreme',
    path: '/defy-extreme',
    component: DefyExtreme,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'DefyExtreme'
    }
  },
  {
    name: 'plp',
    path: '/products/:slug',
    component: PLP,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Product List Page'
    }
  },
  // {
  //   name: 'product-list',
  //   path: '/products-list',
  //   component: Products,
  //   meta: {
  //     transition: 'SpeedTransitionPage',
  //     pageSubCategory: '',
  //     pageMidCategory: '',
  //     pageTopCategory: '',
  //     pageType: 'Poduct List Page'
  //   }
  // },
  {
    name: 'product-details',
    path: '/product/:uid',
    component: ProductDetails,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Product page'
    }
  },
  {
    name: 'store-locator',
    path: '/store-locator',
    component: StoreLocator,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Store Locator'
    }
  },
  {
    name: 'cart',
    path: '/cart',
    component: Cart,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Cart Detail',
      pageType: 'Cart'
    }
  },
  {
    name: 'my-profile',
    path: '/my-profile',
    component: MyProfile,
    beforeEnter: (to, from, next) => {
      let { url } = currentStoreView()
      url = url + '/' || '/'
      const isUserLoggedIn = rootStore.getters['user/isLoggedIn']
      if (isUserLoggedIn) next()
      else next(url + 'login')
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'My Profile',
      pageType: 'Account'
    }
  },
  {
    name: 'login-or-guest',
    path: '/login-or-guest',
    component: LoginOrGuest,
    beforeEnter: (to, from, next) => {
      if (from && from.name && from.name.includes('checkout')) {
        next('cart')
      } else {
        next()
      }
    },
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Login or Guest',
      pageType: 'Account'
    }
  },
  {
    name: 'news',
    path: '/news/:uid',
    component: News,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'News Details',
      pageType: 'News'
    }
  },
  // {
  //   name: 'news-list',
  //   path: '/news',
  //   component: NewsList,
  //   meta: {
  //     transition: 'SpeedTransitionPage',
  //     pageSubCategory: '',
  //     pageMidCategory: '',
  //     pageTopCategory: 'News List',
  //     pageType: 'News'
  //   }
  // },
  {
    name: 'news-landing',
    path: '/news',
    component: NewsLanding,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'News Landing',
      pageType: 'News'
    }
  },
  {
    name: 'newsletter',
    path: '/newsletter',
    component: Newsletter,
    beforeEnter: (to, from, next) => {
      let { url, contact } = currentStoreView()
      url = url + '/' || '/'
      if (contact === false) {
        next(url + 'page-not-found')
      } else {
        next()
      }
    },
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Newsletter subscription',
      pageType: 'Newsletter'
    }
  },
  {
    name: 'faq',
    path: '/faq',
    component: FAQ,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Faq',
      pageType: 'Legal'
    }
  },
  {
    name: 'rma',
    path: '/rma/:order',
    component: RMA,
    beforeEnter: (to, from, next) => {
      let { url } = currentStoreView()
      url = url + '/' || '/'
      const isUserLoggedIn = rootStore.getters['user/isLoggedIn']
      if (isUserLoggedIn) next()
      else next(url + 'login')
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Home',
      pageType: 'Home'
    }
  },
  {
    name: 'extracts',
    path: '/extracts',
    component: OrderCertificate,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Extracts'
    }
  },
  {
    name: 'order',
    path: '/order/:order',
    component: Order,
    beforeEnter: (to, from, next) => {
      let { url } = currentStoreView()
      url = url + '/' || '/'
      const isUserLoggedIn = rootStore.getters['user/isLoggedIn']
      if (isUserLoggedIn) next()
      else next(url + 'login')
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Order details',
      pageType: 'Order'
    }
  },
  {
    name: 'checkout',
    path: '/checkout',
    component: Checkout,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Checkout'
    }
  },
  {
    name: 'contact',
    path: '/contact',
    component: Contact,
    beforeEnter: (to, from, next) => {
      let { url, contact } = currentStoreView()
      url = url + '/' || '/'
      if (contact === false) {
        next(url + 'page-not-found')
      } else {
        next()
      }
    },
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Contact form',
      pageType: 'Contact'
    }
  },
  {
    name: 'search',
    path: '/search',
    component: Search,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Search results',
      pageType: 'Search'
    }
  },
  {
    name: 'brand',
    path: '/brand/:uid',
    component: Brand,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Editorial'
    }
  },
  {
    name: 'service',
    path: '/service/:uid',
    component: Service,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: 'Legal'
    }
  },
  {
    name: 'selection',
    path: '/our-selection/:uid',
    component: Selection,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Selection',
      pageType: 'Selection'
    }
  },
  {
    name: 'page',
    path: '/page/:uid',
    component: SimplePage,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Page',
      pageType: 'Simple Page'
    }
  },
  {
    name: 'dispatch',
    path: '/dispatch',
    component: Dispatch,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'System',
      pageType: 'System'
    }
  },
  {
    name: 'warranty-extension',
    path: '/warranty-extension',
    // Rednder login page for warranty-extension path:
    // the user has to login and continute through the main warranty extension process
    component: Login,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Warranty Extension QR Code',
      pageType: 'Warranty Extension'
    }
  },
  {
    name: 'warranty-extension-success',
    path: '/warranty-extension-success',
    component: WarrantyExtensionSuccess,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Warranty Extension',
      pageType: 'Warranty Extension Success'
    }
  },
  {
    name: 'warranty-extension-error',
    path: '/warranty-extension-error',
    component: WarrantyExtensionError,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Warranty Extension',
      pageType: 'Warranty Extension Error'
    }
  },
  {
    name: 'preview-product',
    path: '/preview/product/:uid',
    component: ProductDetails,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-collection',
    path: '/preview/collection/:uid',
    component: Collection,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-news',
    path: '/preview/news/:uid',
    component: News,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-faq',
    path: '/preview/faq/:uid',
    component: FAQ,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-brand',
    path: '/preview/brand/:uid',
    component: Brand,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-service',
    path: '/preview/service/:uid',
    component: Service,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-page',
    path: '/preview/page/:uid',
    component: SimplePage,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'preview-selection',
    path: '/preview/selection/:uid',
    component: Selection,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'pantone-landing',
    path: '/pantone',
    component: PantoneLanding,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Pantone Landing',
      pageType: 'Pantone Landing'
    }
  },
  {
    name: 'el-primero-stories',
    path: '/el-primero-stories',
    component: Stories,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Stories',
      pageType: 'Stories'
    }
  },
  {
    name: 'story-creation',
    path: '/story-creation',
    component: StoryCreation,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'StoryCreation',
      pageType: 'StoryCreation'
    }
  },
  {
    name: 'defy-5hz',
    path: '/defy-skyline',
    component: Defy5hz,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Defy5hz',
      pageType: 'Defy5hz'
    }
  },
  {
    name: 'sitemap',
    path: '/sitemap',
    component: Sitemap,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Sitemap',
      pageType: 'System'
    }
  },
  {
    name: 'store-sitemap',
    path: '/store-sitemap/:uid',
    component: SitemapStore,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Sitemap',
      pageType: 'System'
    }
  },
  {
    name: 'preview-livetour',
    path: '/preview/livetour/:uid',
    component: Livetour,
    meta: {
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Preview',
      pageType: 'System'
    }
  },
  {
    name: 'livetour',
    path: '/livetour/:uid',
    component: Livetour,
    meta: {
      transition: 'SpeedTransitionPage',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'Livetour',
      pageType: 'Livetour'
    }
  },
  {
    name: 'test-components',
    path: '/test-components',
    component: TestComponents,
    meta: {
      transition: '',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: ''
    }
  },
  {
    name: 'the-maison',
    path: '/the-maison',
    component: TheMaison,
    meta: {
      transition: '',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: ''
    }
  },
  {
    name: 'commitment',
    path: '/commitment',
    component: Commitment,
    meta: {
      transition: '',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: '',
      pageType: ''
    }
  },
  {
    name: 'time-to-reach-your-star',
    path: '/time-to-reach-your-star',
    component: TimeToReachYourStar,
    meta: {
      transition: '',
      pageSubCategory: '',
      pageMidCategory: '',
      pageTopCategory: 'TimeToReachYourStar',
      pageType: 'TimeToReachYourStar'
    }
  }
]

export default routes
