import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const storeLocator = ({ address = null, country = null }) => {
  const url = `${config.api.url}/api/ext/store-locator/storeLocator`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: address && country ? JSON.stringify({ address, country }) : address ? JSON.stringify({ address }) : JSON.stringify({ country })
  })
    .then(response => response.json())
    .then(json => {
      if ((json.result && json.result.message)) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}

export const storeCountries = () => {
  const url = `${config.api.url}/api/ext/store-locator/countries?storeCode=${currentStoreView().virtualStoreCode}`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(json => {
      if ((json.result && json.result.message)) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}
