export function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer) {
    let currentSetting = localStorage.getItem('user-color-scheme')
    switch (currentSetting) {
      case null:
        let mode = getComputedStyle(document.documentElement).getPropertyValue('--color-mode').replace(/\"/g, '').trim()
        if (mode === 'dark') setTimeout(() => store.dispatch('darkmode/activate'), 500)
        else setTimeout(() => store.dispatch('darkmode/deactivate'), 500)
        break
      case 'light':
        setTimeout(() => store.dispatch('darkmode/deactivate'), 500)
        break
      case 'dark':
        setTimeout(() => store.dispatch('darkmode/activate'), 500)
        break
    }
  }
}
