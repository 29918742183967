<template>
  <div>
    <h1>Checkout confirmation</h1>
  </div>
</template>
<script>
export default {
  name: 'CheckoutConfirmation'
}
</script>
