
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const add = story => {
  const url = `${config.api.url}/api/ext/story/add?storeCode=${currentStoreView().virtualStoreCode}`
  return fetch(url, {
    method: 'POST',
    body: story
  })
    .then(response => response.json())
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}

export const get = id => {
  const url = `${config.api.url}/api/ext/story/get`
  return fetch(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id })
  })
    .then(response => response.json())
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}

export const all = () => {
  const url = `${config.api.url}/api/ext/story/all`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}
