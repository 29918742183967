import { ActionTree } from 'vuex'
import { adjustMultistoreApiUrl, currentStoreView } from '@vue-storefront/core/lib/multistore'
import { processURLAddress, isServer } from '@vue-storefront/core/helpers'
import * as types from '@vue-storefront/core/modules/user/store/mutation-types'
import config from 'config'
import Vue from 'vue'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import rootStore from '@vue-storefront/core/store'
import { isSiteChanged } from '../../../../src/themes/zenith/utilities/sfccUtils'

export const actions: ActionTree<any, any> = {
  async authorizeClient (context) {
    if (isServer) {
      return
    }

    const currentToken = localStorage ? localStorage.getItem('shop/user/current-token') : null
    const shouldAuthorize = !currentToken || currentToken === 'null' || isSiteChanged()

    if (!isServer && shouldAuthorize) {
      const storeCode = currentStoreView().virtualStoreCode
      const url = `${config.api.url}/api/ext/sfcc-auth/sfccAuth`
      return fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ storeCode })
      })
        .then(response => response.json())
        .then(async json => {
          if (json.result && json.result.message) {
            throw new Error(JSON.stringify(json))
          } else {
            console.log('### SUCCESSFULLY AUTHORIZED CLIENT ###', json.result)
            if (!isServer) {
              console.log('token updated')
              Vue.prototype.$db.usersCollection.setItem('current-token', json.result.access_token).catch((reason) => {
                console.error(reason) // it doesn't work on SSR
              }) // populate cache
              if (json.result.refresh_token) {
                await context.commit(types.USER_TOKEN_CHANGED, { newToken: json.result.access_token, meta: { refreshToken: json.result.refresh_token, siteId: json.meta.siteId} }) // TODO: handle the "Refresh-token" header
                Vue.prototype.$cookies.keys().forEach(cookie => cookie.includes(`cc-nx`) ? Vue.prototype.$cookies.remove(cookie) : null)
                Vue.prototype.$cookies.set(`site_id`, json.meta.siteId)
                Vue.prototype.$cookies.set(`cc-nx${context.state.current ? '' : '-g'}_${json.meta.siteId}`, json.result.refresh_token)
                await Vue.prototype.$db.usersCollection.setItem('current-refresh-token', json.result.refresh_token).catch((reason) => {
                  console.error(reason) // it doesn't work on SSR
                }) // populate cache
              }
            }
            return json.result
          }
        })
    }
  },
  async register(context, payload) {
    var url = config.users.create_endpoint
    if (config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }

    return TaskQueue.execute({
      url: url,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      }
    })
      .then(resp => { return (resp) })
      .catch((e) => {
        throw new Error('[User] Register failed' + e)
      })
  },
  async delete(context, payload) {
    return new Promise((resolve, reject) => {
      if (!context.state.token) {
        reject('[User] Missing token')
      } else {
        return TaskQueue.execute({
          url: config.users.delete_endpoint,
          payload: {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
          }
        })
          .then(response => response.result)
          .then((response) => {
            if (response.message) {
              throw new Error(response.message)
            } else if (response.success) {
              context.commit(types.USER_END_SESSION)
              context.commit(types.USER_TOKEN_CHANGED, { meta: { refreshToken: null } })
              return Promise.all([
                context.dispatch('cart/disconnect', {}, { root: true }),
                context.dispatch('clearCurrentUser'),
                context.dispatch('cart/clear', { recreateAndSyncCart: true }, { root: true })
              ])
                .then(() => { Vue.prototype.$bus.$emit('user-after-logout') })
                .then(() => {
                  return Promise.all([
                    Vue.prototype.$db.ordersHistoryCollection.clear(),
                    Vue.prototype.$db.usersCollection.clear()
                  ])
                })
            }
          })
          .then(() => {
            resolve(null)
          })
          .catch(error => {
            reject(error)
          })

      }
    })

  },
  async logout(context) {
    context.commit(types.USER_END_SESSION)
    context.commit(types.USER_TOKEN_CHANGED, { meta: { refreshToken: null } })

    return new Promise((resolve, reject) => {
      return Promise.all([
        context.dispatch('cart/disconnect', {}, { root: true }),
        context.dispatch('clearCurrentUser')
      ])
        .then(() => { Vue.prototype.$bus.$emit('user-after-logout') })
        .then(() => { resolve(null) })
    })
      .then(() => {
        return Promise.all([
          Vue.prototype.$db.ordersHistoryCollection.clear(),
          Vue.prototype.$db.usersCollection.clear(),
        ])
      }).then(() => {
        return rootStore.dispatch('user/authorizeClient')
      }).then(async (res) => {
        await context.commit(types.USER_TOKEN_CHANGED, { newToken: res.access_token, meta: {refreshToken: res.refresh_token, siteId: res.meta ? res.meta.siteId : ''}})
        await Vue.prototype.$db.usersCollection.setItem('current-refresh-token', res.refresh_token)
        await context.dispatch('cart/clear', { recreateAndSyncCart: true }, { root: true })
      })
      .catch((error) => {
        throw new Error(error)
      })
  },
  async initResetPassword(context, { email }) {
    return TaskQueue.execute({
      url: config.api.url + '/api/ext/user/init-reset-password',
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email, template: 'email_reset'
        })
      }
    })
      .then(response => {
        if (response.result && response.result.message) {
          throw new Error(response.result.message)
        }
        return response
      })
  },
  async resetPassword(context, { token, password, email }) {
    if(!token) {
      throw new Error('Missing token')
    }
    return TaskQueue.execute({
      url: config.users.new_password_endpoint,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ resetToken: token, newPassword: password, email })
      }
    })
      .then(response => {
        if (response.result && response.result.message) {
          throw new Error(response.result.message)
        }
        return response
      })
  },
  async update (context, userData) {
    return TaskQueue.queue({
      url: config.users.me_endpoint,
      payload: {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify(userData)
      },
      callback_event: 'store:user/userAfterUpdate'
    })
  },
}
