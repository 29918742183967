import Vue from 'vue'
import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CartState from '../types/CartState'
import config from 'config'
import { calcItemsHmac } from '@vue-storefront/core/helpers'

export const compareProduct = (record, product) => {
  const compareOptions = (a, b) => {
    const { option_id: aid, option_value: aval } = a[0]
    const { option_id: bid, option_value: bval } = b[0]
    return aid == bid && aval == bval
  }
  const compareBundle = (a, b) => {
    if (a.length !== b.length) {
      return false
    }

    if (a.length === 1 && b.length === 1) {
      if (a[0] && b[0]) {
        return a[0].option_id === b[0].option_id && a[0].option_selections[0] === b[0].option_selections[0]
      }
    }
    if (a.length === 2 && b.length === 2) {
      const bundleEqual =
        a[0].option_id === b[0].option_id && a[0].option_selections[0] === b[0].option_selections[0]
        &&
        a[1].option_id === b[1].option_id && a[1].option_selections[0] === b[1].option_selections[0]
      return bundleEqual
    }
    return false
    // return JSON.stringify(a) === JSON.stringify(b)
  }
  if (product.product_option && product.product_option.extension_attributes && product.product_option.extension_attributes.custom_options
    && record.product_option && record.product_option.extension_attributes && record.product_option.extension_attributes.bundle_options) {
    return false
  }
  if (product.product_option && product.product_option.extension_attributes && product.product_option.extension_attributes.bundle_options &&
    record.product_option && record.product_option.extension_attributes && record.product_option.extension_attributes.custom_options) {
    return false
  }
  const isEqual =
    record.sku === product.sku &&
      product.product_option && product.product_option.extension_attributes && product.product_option.extension_attributes.custom_options
      &&
      record.product_option && record.product_option.extension_attributes && record.product_option.extension_attributes.custom_options
      ? compareOptions(record.product_option.extension_attributes.custom_options, product.product_option.extension_attributes.custom_options)
      : record.sku === product.sku &&
        product.product_option && product.product_option.extension_attributes && product.product_option.extension_attributes.bundle_options
        &&
        record.product_option && record.product_option.extension_attributes && record.product_option.extension_attributes.bundle_options
        ? compareBundle(record.product_option.extension_attributes.bundle_options, product.product_option.extension_attributes.bundle_options)
        : record.sku === product.sku
  return isEqual
}

const mutations: MutationTree<CartState> = {
  /**
   * Add product to cart
   * @param {Object} product data format for products is described in /doc/ElasticSearch data formats.md
   */
  [types.CART_ADD_ITEM](state, { product }) {
    const record = state.cartItems.find(p => {
      // CORE OVERRIDE: USE CUSTOM COMPARE FUNCTION
      return compareProduct(p, product)
    })
    if (!record) {
      let item = {
        ...product,
        qty: product.attribute_set_name === "Watch Clinic" ? product.qty : 1
      }
      Vue.prototype.$bus.$emit('cart-before-add', { product: item })
      state.cartItems.push(item)
    } else {
      Vue.prototype.$bus.$emit('cart-before-update', { product: record })
      record.qty += 1
    }
  },
  [types.CART_SET_ITEMS_HASH](state, hash = null) {
    state.cartItemsHash = hash
  },
  [types.CART_SET_SYNC](state) {
    state.cartServerLastSyncDate = new Date().getTime()
  },
  [types.CART_SET_TOTALS_SYNC](state) {
    state.cartServerLastTotalsSyncDate = new Date().getTime()
  },
  [types.CART_DEL_ITEM](state, { product, removeByParentSku = true }) {
    Vue.prototype.$bus.$emit('cart-before-delete', { items: state.cartItems })
    // CORE OVERRIDE: USE CUSTOM COMPARE FUNCTION
    state.cartItems = state.cartItems.filter(p => !compareProduct(p, product))
    Vue.prototype.$bus.$emit('cart-after-delete', { items: state.cartItems })
  },
  [types.CART_DEL_NON_CONFIRMED_ITEM](state, { product, removeByParentSku = true }) {
    Vue.prototype.$bus.$emit('cart-before-delete', { items: state.cartItems })
    // CORE OVERRIDE: USE CUSTOM COMPARE FUNCTION
    state.cartItems = state.cartItems.filter(p => !compareProduct(p, product))
    Vue.prototype.$bus.$emit('cart-after-delete', { items: state.cartItems })
  },
  [types.CART_UPD_ITEM](state, { product, qty }) {
    // CORE OVERRIDE: USE CUSTOM COMPARE FUNCTION
    const record = state.cartItems.find(p => compareProduct(p, product))

    if (record) {
      Vue.prototype.$bus.$emit('cart-before-update', { product: record })
      record.qty = parseInt(qty)
      Vue.prototype.$bus.$emit('cart-after-update', { product: record })
    }
  },
  [types.CART_UPD_ITEM_PROPS](state, { product }) {
    // let record = state.cartItems.find(p => compareProduct(p, product) || (product.server_item_id && p.server_item_id && p.server_item_id === product.server_item_id))
    // CORE OVERRIDE: USE CUSTOM COMPARE FUNCTION
    let record = state.cartItems.find(p => compareProduct(p, product))
    if (record) {
      Vue.prototype.$bus.$emit('cart-before-itemchanged', { item: record })
      record = Object.assign(record, product)
      Vue.prototype.$bus.$emit('cart-after-itemchanged', { item: record })
    }
  },
  [types.CART_UPD_SHIPPING](state, shippingMethod) {
    state.shipping = shippingMethod
  },
  [types.CART_LOAD_CART](state, storedItems) {
    state.cartItems = storedItems || []
    state.cartIsLoaded = true

    // Vue.prototype.$bus.$emit('order/PROCESS_QUEUE', { config: config }) // process checkout queue
    Vue.prototype.$bus.$emit('sync/PROCESS_QUEUE', { config }) // process checkout queue
    Vue.prototype.$bus.$emit('application-after-loaded')
    Vue.prototype.$bus.$emit('cart-after-loaded')
  },
  [types.CART_LOAD_CART_SERVER_TOKEN](state, token) {
    state.cartServerToken = token
  },
  [types.CART_UPD_TOTALS](state, { itemsAfterTotals, totals, platformTotalSegments }) {
    state.itemsAfterPlatformTotals = itemsAfterTotals
    state.platformTotals = totals
    state.platformTotalSegments = platformTotalSegments
    Vue.prototype.$bus.$emit('cart-after-updatetotals', { platformTotals: totals, platformTotalSegments: platformTotalSegments })
  },
  [types.CART_UPD_PAYMENT](state, paymentMethod) {
    state.payment = paymentMethod
  },
  [types.CART_TOGGLE_MICROCART](state) {
    state.isMicrocartOpen = !state.isMicrocartOpen
  }
}

export default mutations
