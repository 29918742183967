
<template>
  <Observer name="lazy-picture" :className="[className, 'lazy-picture']" @enter="enter" :rootMargin="rootMargin ? rootMargin : '0px'" @leave="leave">
    <picture v-if="shouldRenderPictureTag && item.image && item.image.url">
      <source :srcset="imageSrcSetMobile" v-if="item.imageMobile && item.imageMobile.url" media="(max-width: 767px)"/>
      <source :srcset="imageSrcSet" :media="item.imageMobile && item.imageMobile.url ? '(min-width: 768px)' : ''"/>
      <img ref="picture" :src="imageLowDefinition" :alt="item.image.alt ? item.image.alt : ' '" :width="item.image.width ? item.image.width : false" :height="item.image.height ? item.image.height : false" loading="lazy"/>
    </picture>
    <img v-if="!shouldRenderPictureTag && item.image && item.image.url"
         :src="imageLowDefinition" :alt="item.image.alt ? item.image.alt : ' '"
         :width="item.image.width ? item.image.width : false"
         :height="item.image.height ? item.image.height : false"
         loading="lazy"
         :srcset="imageSrcSet"
         ref="picture" />
  </Observer>
</template>

<script>
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'

export default {
  props: ['item', 'itemprop', 'desktopRatio', 'mobileRatio', 'preserveRatio', 'rootMargin', 'className'],
  name: 'lazy-picture',
  data () {
    return {
      appeared: false,
      shouldRenderPictureTag: !!this.item.imageMobile // Tag picture will be rendered only if there is a mobile image
    }
  },
  computed: {
    imageLowDefinition () {
      return this.item.image.url.indexOf('.gif') > -1 ? '/assets/img/spacer.gif' : ImageManager.getLowDefinition(this.item.image.url, 400, this.desktopRatio, this.preserveRatio)
    },
    imageSrcSetMobile () {
      return ImageManager.getSrcSet(this.item.imageMobile.url, 1600, 200, 100, this.mobileRatio, this.preserveRatio)
    },
    imageSrcSet () {
      return ImageManager.getSrcSet(this.item.image.url, 2000, 200, 100, this.desktopRatio, this.preserveRatio)
    }
  },
  methods: {
    enter() {
      if (!this.appeared && this.$refs.picture) {
        this.appeared = true
      }
    },
    leave() {
    }
  },
  metaInfo () {
    return this.itemprop ? {
      meta: [
        { vmid: 'image', name: 'image', content: this.item.url + '?im=Resize=(400,400)' }
      ]
    } : {}
  }
}
</script>
