const abtastySnippet = (abid) => (function (i, s, o, g, r, a, m) {
  const date = new Date()
  i[r] = i[r] || []
  i['abtiming'] = date.getTime()
  a = s.createElement(o)
  m = s.getElementsByTagName(o)[0]
  // a.async = 1
  a.async = false
  a.defer = true
  a.src = g
  m.parentNode.insertBefore(a, m)
})(window, document, 'script', `//try.abtasty.com/${abid}.js`, '_abtasty')

export function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer && config.abtasty && config.abtasty.id && config.abtasty.active) {
    abtastySnippet(config.abtasty.id)
  }
}
