
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const downloadForm = ({ contact, type, lang, url }) => {
  const { virtualStoreCode } = currentStoreView()
  const _url = `${config.api.url}/api/ext/download/post?storeCode=${virtualStoreCode}`
  return fetch(_url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ document: { contact, type, lang, url } })
  })
    .then(response => response.json())
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result
      }
    })
}
