import config from 'config'

const onetrustSnippet = (path) => {
  let script = document.createElement('script')
  script.src = `${path}`
  script.type = 'text/javascript'
  script.onload = (window as any).OptanonWrapper
  script.async = true
  script.defer = true
  script.setAttribute('data-document-language', 'true')
  script.setAttribute('data-domain-script', config.onetrust.domainScript)
  script.setAttribute('charset', "UTF-8")
  document.head.append(script)
}

export function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer && config.onetrust && config.onetrust.active) {
    onetrustSnippet(config.onetrust.path)
  }
}
