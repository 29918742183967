import config from 'config'
/* eslint-disable import/first */
if (process.browser) {
  require('intersection-observer')
}
// if (process.browser && config.prismic && config.prismic.toolbar && config.prismic.toolbar.active) {
//   const prismicToolbarScript = document.createElement('script')
//   prismicToolbarScript.src = `https://static.cdn.prismic.io/prismic.js?repo=${config.prismic.repository}&new=true`
//   prismicToolbarScript.async = true
//   prismicToolbarScript.defer = true
//   document.body.append(prismicToolbarScript)
// }
import App from './App.vue'
import { setupMultistoreRoutes, currentStoreView } from '@vue-storefront/core/lib/multistore'
import routes from './router'
import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import VBodyScrollLock from 'v-body-scroll-lock'
import '@vue-storefront/core/lib/passive-listeners'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import { once } from '@vue-storefront/core/helpers'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import AsyncReady from 'vue-asyncready'

import TransitionExpand from './components/utils/TransitionExpand'
import SpeedTransitionPage from './components/utils/SpeedTransitionPage'
import Observer from './components/utils/Observer'
import CmpRenderer from './components/utils/CmpRenderer'
// import TitleTextCenter from './components/TitleTextCenter'
// import ServiceThumbs from './components/ServiceThumbs'
// import TaglineHorizontalAutoScroll from './components/TaglineHorizontalAutoScroll'
// import TaglineHorizontalAutoScrollPantone from './components/TaglineHorizontalAutoScrollPantone'
// import VideoContainer from './components/VideoContainer'
// import VideoGif from './components/VideoGif'
// import DeepDive from './components/DeepDive'
// import HeroStaticBanner from './components/HeroStaticBanner'
// import BaselineGif from './components/BaselineGif'
// import StoreLocatorWidget from './components/StoreLocatorWidget'
// import ImmersiveSlider from './components/ImmersiveSlider'
// import SliderLimitedEdition from './components/SliderLimitedEdition'
import Cta from './components/utils/Cta'
import HRCtaTitleText from './components/home-rework/HRCtaTitleText'
import HeroImage from './components/HeroImage.vue'
import AdaptiveImage from './components/AdaptiveImage.vue'
import HeroCountdown from './components/HeroCountdown.vue'

// import FullscreenImageBanner from './components/FullscreenImageBanner'
// import EditoTextImage from './components/EditoTextImage'
// import ImageUnderText from './components/ImageUnderText'
import ProductSlider from './components/ProductSlider'
// import ThreeColumns from './components/ThreeColumns'
// import WatchCollection from './components/WatchCollection'

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  const progressBarOptions = {
    color: '#ffffff',
    failedColor: '#874b4b',
    thickness: '10px',
    transition: {
      opacity: '0.2s'
    }
  }
  Vue.use(VueProgressBar, progressBarOptions)
})
once('__VUE_EXTEND_VBSCRLCK', () => {
  Vue.use(VBodyScrollLock)
})
once('__VUE_EXTEND_VUELIDATE', () => {
  Vue.use(Vuelidate)
})
once('__VUE_EXTEND_GMAPS', () => {
  Vue.use(VueGoogleMaps, {
    installComponents: true,
    load: {
      key: config.google.maps.key,
      libraries: 'geometry'
    }
  })
  // Vue.use(VueGoogleMaps)
})
once('__VUE_EXTEND_VUECKIE', () => {
  Vue.use(VueCookies, { expires: '90d'})
})
Vue.component('adaptive_image', AdaptiveImage)

Vue.component('hero_countdown', HeroCountdown)
// cross page component, no lazy loading
Vue.component('hero_image', HeroImage)
Vue.component('TransitionExpand', TransitionExpand)
Vue.component('SpeedTransitionPage', SpeedTransitionPage)
Vue.component('Observer', Observer)
Vue.component('CmpRenderer', CmpRenderer)

Vue.component('two_images_hero_banner', () => import(/* webpackChunkName: "two-image-hero-banner-cpt" */ './components/cms/TwoImagesHeroBanner'))
// Vue.component('CmpRenderer', () => import(/* webpackChunkName: "cmp-renderer-cpt" */ './components/utils/CmpRenderer'))
// Vue.component('edito_text_image', EditoTextImage)
Vue.component('edito_text_image', () => import(/* webpackChunkName: "edito-txt-image-cpt" */ './components/EditoTextImage'))
// Vue.component('hero_static_banner', HeroStaticBanner)
Vue.component('hero_static_banner', () => import(/* webpackChunkName: "hero-static-cpt" */ './components/HeroStaticBanner'))
Vue.component('moc_hero_static_banner', () => import(/* webpackChunkName: "hero-static-cpt" */ './components/MOCHeroStaticBanner'))
Vue.component('image_under_text', () => import(/* webpackChunkName: "image-under-text-cpt" */ './components/ImageUnderText'))
// Vue.component('tagline_horizontal_auto_scroll', TaglineHorizontalAutoScroll)
Vue.component('tagline_horizontal_auto_scroll', () => import(/* webpackChunkName: "tagline-auto-scroll-cpt" */ './components/TaglineHorizontalAutoScroll'))
// Vue.component('tagline_horizontal_auto_scroll_pantone', TaglineHorizontalAutoScrollPantone)
Vue.component('tagline_horizontal_auto_scroll_pantone', () => import(/* webpackChunkName: "tagline-auto-scroll-pantone-cpt" */ './components/TaglineHorizontalAutoScrollPantone'))
// Vue.component('video_container', VideoContainer)
Vue.component('video_container', () => import(/* webpackChunkName: "video-container-cpt" */ './components/VideoContainer'))
// Vue.component('video_gif', VideoGif)
Vue.component('deep_dive', () => import(/* webpackChunkName: "deep-dive-cpt" */ './components/DeepDive'))
Vue.component('service_thumbs', () => import(/* webpackChunkName: "service-thumbs-cpt" */ './components/ServiceThumbs'))
Vue.component('store_locator_widget', () => import(/* webpackChunkName: "store-locator-widget-cpt" */ './components/StoreLocatorWidget'))
Vue.component('slider_limited_edition', () => import(/* webpackChunkName: "slider-limited-cpt" */ './components/SliderLimitedEdition'))

Vue.component('immersive_slider', () => import(/* webpackChunkName: "immersive-slider-cpt" */ './components/ImmersiveSlider'))
Vue.component('fullscreen_image_banner', () => import(/* webpackChunkName: "fullscreen-image-banner-cpt" */ './components/FullscreenImageBanner'))
// Vue.component('product_slider', () => import(/* webpackChunkName: "product-slider-cpt" */ './components/ProductSlider'))
Vue.component('product_slider', ProductSlider)
Vue.component('straps_compatible_watches_slider', () => import(/* webpackChunkName: "straps_slider-cpt" */ './components/CompatibleSlider'))
Vue.component('straps_slider', () => import(/* webpackChunkName: "straps_slider-cpt" */ './components/StrapsSlider'))
Vue.component('product_related_slider', () => import(/* webpackChunkName: "product_related_slider-cpt" */ './components/ProductSlider'))

Vue.component('title_text_center', () => import(/* webpackChunkName: "title-text-center-cpt" */ './components/TitleTextCenter'))
Vue.component('three_columns', () => import(/* webpackChunkName: "three-columns-cpt" */ './components/ThreeColumns'))
Vue.component('interactive_highlight_slider', () => import(/* webpackChunkName: "interactive_highlight_slider-cpt" */ './components/InteractiveHighlightSlider'))

// homepage specific
Vue.component('collections_thumbs', () => import(/* webpackChunkName: "collection-thumbs-homepage-cpt" */ './components/CollectionsThumbs'))
Vue.component('slider_full_page', () => import(/* webpackChunkName: "slider-homepage-cpt" */ './components/SliderFullPage'))
// Vue.component('hero_image', () => import(/* webpackChunkName: "hero-image-cpt" */ './components/HeroImage'))
// Vue.component('hero_countdown', () => import(/* webpackChunkName: "hero-image-cpt" */ './components/HeroCountdown'))

// editorial specific
Vue.component('title_text', () => import(/* webpackChunkName: "editorial-cpt" */ './components/TitleText'))
Vue.component('headline_title_text', () => import(/* webpackChunkName: "editorial-cpt" */ './components/utils/HeadlineTitleText'))
Vue.component('section_headline_title_text', () => import(/* webpackChunkName: "editorial-cpt" */ './components/SectionHeadlineTitleText'))
Vue.component('title_image', () => import(/* webpackChunkName: "editorial-cpt" */ './components/TitleImage'))
Vue.component('title_text_image', () => import(/* webpackChunkName: "editorial-cpt" */ './components/TitleTextImage'))
Vue.component('youtube_embed', () => import(/* webpackChunkName: "editorial-cpt" */ './components/YoutubeEmbed'))
Vue.component('quote', () => import(/* webpackChunkName: "editorial-cpt" */ './components/Quote'))
Vue.component('basic_page_title', () => import(/* webpackChunkName: "editorial-cpt" */ './components/BasicPageTitle'))
// Vue.component('hero_static_banner', () => import(/* webpackChunkName: "editorial-cpt" */ './components/HeroStaticBanner'))
Vue.component('countdown_banner', () => import(/* webpackChunkName: "editorial-cpt" */ './components/CountDownBanner'))
Vue.component('html_embed', () => import(/* webpackChunkName: "editorial-cpt" */ './components/HtmlEmbed'))

// product detail specific
Vue.component('product_header', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/ProductHeader'))
Vue.component('product_zoom', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/ProductZoom'))
Vue.component('sticky_product_bar', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/StickyProductBar'))
Vue.component('image_toggler', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/ImageToggler'))
Vue.component('technical_specification_hero', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/TechnicalSpecificationHero'))
Vue.component('technical_specification_manual', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/TechnicalSpecificationManual'))
Vue.component('technical_specification', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/TechnicalSpecification'))
Vue.component('color_selector', () => import(/* webpackChunkName: "product-detail-cpt" */ './components/ColorSelector'))

// contact form specific
Vue.component('contact_form', () => import(/* webpackChunkName: "contact-form-cpt" */ './components/ContactForm'))
Vue.component('custom_form', () => import(/* webpackChunkName: "contact-form-cpt" */ './components/PrismicCustomForm'))

// FAQ page specific
Vue.component('faq_category', () => import(/* webpackChunkName: "faq-cpt" */ './components/FaqCategory'))
Vue.component('faq_item', () => import(/* webpackChunkName: "faq-cpt" */ './components/FaqItem'))

Vue.component('baseline_gif', () => import(/* webpackChunkName: "baseline-cpt" */ './components/BaselineGif'))
Vue.component('video_gif', () => import(/* webpackChunkName: "videogif-cpt" */ './components/VideoGif'))

Vue.component('download', () => import(/* webpackChunkName: "download-cpt" */ './components/Download'))
Vue.component('download_form_catalog', () => import(/* webpackChunkName: "download-form-catalog-cpt" */ './components/DownloadFormCatalog'))

Vue.component('watch_collection', () => import(/* webpackChunkName: "watch-collection-cpt" */ './components/WatchCollection'))
Vue.component('video_player', () => import(/* webpackChunkName: "video-player-cpt" */ './components/VideoPlayer'))
Vue.component('video_player_fs', () => import(/* webpackChunkName: "video-player-fs-cpt" */ './components/VideoPlayerFS'))
Vue.component('video_streaming', () => import(/* webpackChunkName: "video-player-streaming-cpt" */ './components/VideoStreaming'))

// collection landing specific
// Vue.component('chronomaster_board1', () => import(/* webpackChunkName: "chronomaster_board1-cpt" */ './components/chronomaster/ChronomasterBoard1'))
// Vue.component('chronomaster_board2', () => import(/* webpackChunkName: "chronomaster_board2-cpt" */ './components/chronomaster/ChronomasterBoard2'))
// Vue.component('chronomaster_board3', () => import(/* webpackChunkName: "chronomaster_board3-cpt" */ './components/chronomaster/ChronomasterBoard3'))
// Vue.component('chronomaster_board4', () => import(/* webpackChunkName: "chronomaster_board4-cpt" */ './components/chronomaster/ChronomasterBoard4'))
// Vue.component('chronomaster_board5', () => import(/* webpackChunkName: "chronomaster_board5-cpt" */ './components/chronomaster/ChronomasterBoard5'))
// Vue.component('chronomaster_board6', () => import(/* webpackChunkName: "chronomaster_board6-cpt" */ './components/chronomaster/ChronomasterBoard6'))
// Vue.component('chronomaster_board7', () => import(/* webpackChunkName: "chronomaster_board7-cpt" */ './components/chronomaster/ChronomasterBoard7'))
// Vue.component('chronomaster_board8', () => import(/* webpackChunkName: "chronomaster_board8-cpt" */ './components/chronomaster/ChronomasterBoard8'))

Vue.component('defy_extreme_editorial', () => import(/* webpackChunkName: "defy-extreme-editorial-cpt" */ './components/defy-extreme/Editorial'))
Vue.component('defy_extreme_fullscreen_video', () => import(/* webpackChunkName: "defy-extreme-fullscreen-video-cpt" */ './components/defy-extreme/FullscreenVideo'))
Vue.component('defy_extreme_header', () => import(/* webpackChunkName: "defy-extreme-header-cpt" */ './components/defy-extreme/Header'))
Vue.component('defy_extreme_sticky_header', () => import(/* webpackChunkName: "defy-extreme-sticky-header-cpt" */ './components/defy-extreme/StickyHeader'))
Vue.component('defy_extreme_horizontal_slider', () => import(/* webpackChunkName: "defy-extreme-horizontal-slider-cpt" */ './components/defy-extreme/HorizontalSlider'))
Vue.component('defy_extreme_media_text', () => import(/* webpackChunkName: "defy-extreme-media-text-cpt" */ './components/defy-extreme/MediaText'))
Vue.component('defy_extreme_movement', () => import(/* webpackChunkName: "defy-extreme-movement-cpt" */ './components/defy-extreme/Movement'))
Vue.component('defy_extreme_product_slider', () => import(/* webpackChunkName: "defy-extreme-product-slider-cpt" */ './components/defy-extreme/ProductSlider'))

// Home rework components
// Vue.component('hr_cta_title_text', () => import(/* webpackChunkName: "home-rework-cta-title-text" */ './components/home-rework/HRCtaTitleText'))
Vue.component('hr_dual_tile', () => import(/* webpackChunkName: "home-rework-dual-tile" */ './components/home-rework/HRDualTile'))
Vue.component('hr_full_width_tile', () => import(/* webpackChunkName: "home-rework-full-width-tile" */ './components/home-rework/HRFullWidthTile'))
Vue.component('hr_hero_banner', () => import(/* webpackChunkName: "home-rework-hero-banner" */ './components/home-rework/HRHeroBanner'))
Vue.component('hr_new_products', () => import(/* webpackChunkName: "home-rework-new-products" */ './components/home-rework/HRNewProducts'))

// Product Details rework components
Vue.component('pdpr_image_title_text', () => import(/* webpackChunkName: "pdp-rework-staggered-visual" */ './components/StaggeredVisuals'))
Vue.component('pdpr_image_title_text_icon', () => import(/* webpackChunkName: "pdp-rework-movement" */ './components/Movement'))
// Vue.component('pdpr_services_collapse', () => import(/* webpackChunkName: "pdp-rework-services-collapse */ './components/product-rework/PDPRServiceCollapse'))

Vue.component('cta', Cta)
Vue.component('hr_cta_title_text', HRCtaTitleText)
// Vue.component('cta', () => import(/* webpackChunkName: "zenith-cta-cpt" */ './components/utils/Cta'))

Vue.component('defy_5hz_image_text', () => import(/* webpackChunkName: "defy-5hz-movement-cpt" */ './components/defy-5hz/Detail'))
Vue.component('defy_5hz_dual_product', () => import(/* webpackChunkName: "defy-5hz-dual-product-cpt" */ './components/defy-5hz/Dual'))
Vue.component('defy_5hz_gif', () => import(/* webpackChunkName: "defy-5hz-gif-cpt" */ './components/defy-5hz/FlashImg'))
Vue.component('defy_5hz_hero', () => import(/* webpackChunkName: "defy-5hz-hero-cpt" */ './components/defy-5hz/Intro'))
Vue.component('defy_5hz_video_text', () => import(/* webpackChunkName: "defy-5hz-video-cpt" */ './components/defy-5hz/Video'))
// Vue.component('defy_5hz_dual_image', () => import(/* webpackChunkName: "defy-5hz-video-cpt" */ './components/defy-5hz/????'))

Vue.component('podcast_expertise', () => import(/* webpackChunkName: "podcast-expertise" */ './components/PodcastExpertise'))
Vue.component('podcast_fashion', () => import(/* webpackChunkName: "podcas-fashiont" */ './components/PodcastFashion'))
Vue.component('movement_selector', () => import(/* webpackChunkName: "movement_selector" */ './components/MovementSelector'))
Vue.component('generic_grid', () => import(/* webpackChunkName: "generic_grid" */ './components/GenericGrid'))

Vue.component('large_highlights', () => import(/* webpackChunkName: "large_highlights" */ './components/LargeHighlights'))
Vue.component('small_highlights', () => import(/* webpackChunkName: "small_highlights" */ './components/SmallHighlights'))
// Vue.component('generic_grid', () => import(/* webpackChunkName: "generic_grid" */ './components/GenericGrid'))

Vue.component('basic_countdown', () => import(/* webpackChunkName: "basic-countdown-cpt" */ './components/BasicCountdown'))

// Commitment
Vue.component('full_width_tile', () => import(/* webpackChunkName: "full-width-tile-cpt" */ './components/FullWidthTile'))
Vue.component('list_tile', () => import(/* webpackChunkName: "list-tile-cpt" */ './components/ListTile'))
Vue.component('dual_tile', () => import(/* webpackChunkName: "dual-tile-cpt" */ './components/DualTile'))

// Time to reach your star
Vue.component('slider_tabs_bar', () => import(/* webpackChunkName: "dual-tile-cpt" */ './components/SliderTabsBar'))

Vue.component('timeline_decade', () => import(/* webpackChunkName: "timeline-decade-cpt" */ './components/TimelineDecade'))
Vue.component('timeline_year', () => import(/* webpackChunkName: "timeline-year-cpt" */ './components/TimelineYear'))
Vue.component('timeline_toolbar', () => import(/* webpackChunkName: "timeline-year-cpt" */ './components/TimelineToolbar'))

// Vue.component('adaptive_image', () => import(/* webpackChunkName: "adaptive-image-cpt" */ './components/AdaptiveImage'))
Vue.component('category_block', () => import(/* webpackChunkName: "category_block-cpt" */ './components/CategoryBlock'))
Vue.component('category_toolbar', () => import(/* webpackChunkName: "category_toolbar-cpt" */ './components/CategoryToolbar'))
Vue.component('news_promote', () => import(/* webpackChunkName: "news-promote-cpt" */ './components/NewsPromote'))
Vue.component('generic_toolbar', () => import(/* webpackChunkName: "generic_toolbar-cpt" */ './components/GenericToolbar'))
Vue.component('ambassadors_tile', () => import(/* webpackChunkName: "adaptive-image-cpt" */ './components/AmbassadorsTile'))
Vue.component('ambassadors_slider', () => import(/* webpackChunkName: "adaptive-image-cpt" */ './components/AmbassadorsSlider'))

Vue.component('icons_slider_banner', () => import(/* webpackChunkName: "icons_slider_banner-cpt" */ './components/IconsSliderBanner'))
Vue.component('two_large_images', () => import(/* webpackChunkName: "two_large_images-cpt" */ './components/TwoLargeImages'))
Vue.component('scroll_animation', () => import(/* webpackChunkName: "scroll_animation-cpt" */ './components/ScrollAnimation'))

Vue.component('anchor', () => import(/* webpackChunkName: "anchor-cpt" */ './components/cms/Anchor'))
Vue.component('editorial_highlight', () => import(/* webpackChunkName: "editorial_highlight-cpt" */ './components/cms/EditorialHighlight'))
Vue.component('editorial_breadcrumb', () => import(/* webpackChunkName: "editorial_breadcrumb-cpt" */ './components/cms/EditorialBreadcrumb'))
Vue.component('images_tab_accordion', () => import(/* webpackChunkName: "images_tab_accordion-cpt" */ './components/cms/ImagesTabAccordion.vue'))

// directives
Vue.directive('distance-unit', {
  inserted: (element) => {
    const distanceUnit = currentStoreView().i18n.distanceUnit
    const ratio = distanceUnit === 'kilometer' ? 1 : distanceUnit === 'mile' ? 0.621371 : 1
    const unit = distanceUnit === 'kilometer' ? 'km' : distanceUnit === 'mile' ? 'mi' : 'km'
    element.textContent = element.textContent.length ? `${(+element.textContent * ratio).toFixed(2)} ${unit}` : element.textContent
  }
})

const transformNormalizedCurrency = (value) => {
  const { storeCode } = currentStoreView()
  if (!value.includes('$')) return value
  switch (storeCode) {
    case 'en_ca': {
      // CA$ 13000.00
      return `CA$ ${value.replace(/\$/, '')}`
    }
    case 'fr_ca': {
      // CA$ 13000.00
      return `CA$ ${value.replace(/\$/, '')}`
    }
    case 'en_nz': {
      // NZ$ 17600.00
      return `NZ$ ${value.replace(/\$/, '')}`
    }
    case 'en_sg': {
      // S$ 14500.00
      return `S$ ${value.replace(/\$/, '')}`
    }
    case 'en_au': {
      // AU$ 14300.00
      return `AU$ ${value.replace(/\$/, '')}`
    }
    default: {
      return value
    }
  }
}

const transformNormalizedDollars = (value, currencyCode = null) => {
  if (!currencyCode || !value.includes('$')) return value
  switch (currencyCode) {
    case 'CAD': {
      // CA$ 13000.00
      return `CA$ ${value.replace('CA$', '')}`
    }
    case 'NZD': {
      // NZ$ 17600.00
      return `NZ$ ${value.replace('NZ$', '')}`
    }
    case 'SGD': {
      // S$ 14500.00
      return `S$ ${value.replace('SGD', '')}`
    }
    case 'AUD': {
      // AU$ 14300.00
      return `AU$ ${value.replace('A$', '')}`
    }
    default: {
      return value
    }
  }
}

export const getPriceWithCurrency = (value, currencyCode) => {
  const { i18n, is_glboal_e_store: isGlobalEStore, virtualLocale } = currentStoreView()
  let { currencySign, defaultLocale } = i18n
  if (isGlobalEStore || currencyCode) {
    currencySign = currencyCode
    if (['CAD', 'AUD', 'SGD', 'NZD'].includes(currencyCode)) {
      return isNaN(+value) ? value : currencySign && defaultLocale ? transformNormalizedDollars(new Intl.NumberFormat('en-us', { style: 'currency', currency: currencySign }).format(value), currencyCode) : value
    }
  }
  if (virtualLocale) defaultLocale = virtualLocale
  return isNaN(+value) ? value : currencySign && defaultLocale ? transformNormalizedCurrency(new Intl.NumberFormat(defaultLocale, { style: 'currency', currency: currencySign }).format(value)) : value
}

Vue.filter('currency', (value, currencyCode) => {
  return getPriceWithCurrency(value, currencyCode)
})

Vue.directive('currency', {

  inserted: (element, currencyCode) => {
    const { i18n, is_glboal_e_store: isGlobalEStore, virtualLocale } = currentStoreView()
    let { currencySign, defaultLocale } = i18n
    if (isGlobalEStore || currencyCode) {
      currencySign = currencyCode
    }
    if (virtualLocale) defaultLocale = virtualLocale
    const value = element.textContent
    element.textContent = isNaN(+value) ? value : currencySign && defaultLocale ? transformNormalizedCurrency(new Intl.NumberFormat(defaultLocale, { style: 'currency', currency: currencySign }).format(value)) : value
  },
  update: (element, currencyCode) => {
    const { i18n, is_glboal_e_store: isGlobalEStore } = currentStoreView()
    let { currencySign, defaultLocale } = i18n
    if (isGlobalEStore || currencyCode) {
      currencySign = currencyCode
    }
    const value = element.textContent
    element.textContent = isNaN(+value) ? value : currencySign && defaultLocale ? transformNormalizedCurrency(new Intl.NumberFormat(defaultLocale, { style: 'currency', currency: currencySign }).format(value)) : value
  }
})

Vue.directive('input-lowercase', {
  update: (el) => {
    if (el && el.value) {
      el.value = el.value.toLowerCase()
    } else {
      el.value = ''
    }
  }
})

Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    window.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }

    // window can be already loaded
    if (document.body.contains(el)) {
      document.body.addEventListener('click', window.event)
    }

    window.addEventListener('load', () => {
      /**
       * append event listener to existing elements
       * in the runtime there may be different re-renderings and the event can be appended multiple times without the inner HTML element (unmounted)
       */
      if (document.body.contains(el)) {
        document.body.addEventListener('click', window.event)
      }
    })
  },
  unbind (el) {
    document.body.removeEventListener('click', window.event)
  }
})

Vue.filter('normalizeString', (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z-A-Z ]/g, '')
    .replace(/ +/, ' ')
    .replace(/\s/g, '-')
    .toLowerCase()
})

const themeEntry = App
// Vue.config.devtools = true
function initTheme (app, router, store, config, ssrContext) {
  // if youre' runing multistore setup this is copying the routed above adding the 'storeCode' prefix to the urls and the names of the routes
  // You can do it on your own and then be able to customize the components used for example for German storeView checkout
  // To do so please execlude the desired storeView from the config.storeViews.mapStoreUrlsFor and map the urls by Your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized },
  /*
  router.options.scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(savedPosition)
        }, 3000)
      })
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 3000)
      })
    }
  }
  */
  once('__VUE_EXTEND_ASYNC_RDY', () => {
    Vue.use(AsyncReady, router, {
      transition: 'SpeedTransitionPage',
      abort: 3000,
      autoClear: true,
      debug: false
    })
  })

  setupMultistoreRoutes(config, router, routes)
  RouterManager.addRoutes(routes, router)
}

export {
  themeEntry,
  initTheme
}
