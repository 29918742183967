import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.SET_RESULTS] (state, payload) {
    state.results = payload
    state.length = Object.entries(payload).map(value => value.length)
  },
  [types.SET_LOADING] (state, payload) {
    state.loading = payload
  }
}
