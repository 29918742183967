// import { isServer } from '@vue-storefront/core/helpers'
// import config from 'config'
// import { currentStoreView } from '@vue-storefront/core/lib/multistore'

const state = {
  watch: null,
  strap: null,
  buckle: null
}
const getters = {
  getWatch: state => state.watch,
  getStrap: state => state.strap,
  getBuckle: state => state.buckle
}
const mutations = {
  setWatch (state, payload) {
    state.watch = payload
  },
  setStrap (state, payload) {
    state.strap = payload
  },
  setBuckle (state, payload) {
    state.buckle = payload
  }
}
const actions = {
  setValue ({ commit, state }, { watch, strap, buckle }) {
    if (watch) commit('setWatch', watch)
    if (strap) commit('setStrap', strap)
    if (buckle) commit('setBuckle', buckle)
  }
}
export const store = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
}
