import { SearchState } from '../types/SearchState'
import { GetterTree } from 'vuex'

export const getters: GetterTree<SearchState, any> = {
  faq: state => state.results.faq,
  service: state => state.results.service,
  brand: state => state.results.brand,
  news: state => state.results.news,
  collections: state => state.results.collections,
  products: state => state.results.products,
  stores: state => state.results.stores,
  results: state => state.results,
  loading: state => state.loading,
  length: state => Object.keys(state.results).reduce((acc, key) => acc += state.results[key].length, 0)
}
