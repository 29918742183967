import config from 'config'

export const chronomasterNow = () => {
  const url = `${config.api.url}/api/ext/timing/now`
  return fetch(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  })
    .then(response => response.json())
    .then(json => json.timestamp)
}
