import { createModule } from '@vue-storefront/core/lib/module'
import { afterRegistration } from './hooks/afterRegistration'
import config from 'config'

const KEY = 'darkmode'
const store = {
  namespaced: true,
  state: {
    activated: false
  },
  mutations: {
    setDarkmode (state, action) {
      if (config.darkmode) {
        state.activated = action === true
        document.documentElement.setAttribute('data-user-color-scheme', (state.activated) ? 'dark' : 'light')
        localStorage.setItem('user-color-scheme', (state.activated) ? 'dark' : 'light')
      } else {
        state.activated = false
        document.documentElement.setAttribute('data-user-color-scheme', 'light')
        // localStorage.setItem('user-color-scheme', 'light')
      }
    }
  },
  actions: {
    toggle ({ commit, state }) {
      // console.log('[Darkmode] action : toggle')
      commit('setDarkmode', !state.activated)
    },
    activate ({ commit, state }) {
      // console.log('[Darkmode] action : activate')
      commit('setDarkmode', true)
    },
    deactivate ({ commit, state }) {
      // console.log('[Darkmode] action : deactivate')
      commit('setDarkmode', false)
    }
  }
}

export const Darkmode = createModule({
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] },
  afterRegistration
})
