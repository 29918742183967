<template>
  <section class="hero-image">
    <div :class="['picture-container', item.use_overlay ? '-overlay' : '', item.theme === 'dark' ? '-dark' : '-default' ]">
      <video v-if="item.video" ref="video" autoplay playsinline loop muted>
        <source v-if="isMobile && item.videoMobile && item.videoMobile.url" ref="source" :src="item.videoMobile.url + '?impolicy=' + item.impolicy" type="video/mp4">
        <source v-else ref="source" :src="item.video.url + '?impolicy=' + item.impolicy" type="video/mp4">
      </video>
      <picture v-else-if="imageUrl">
        <source :srcset="imageSrcSetMobile" v-if="imageMobileUrl" media="(max-width: 767px)" />
        <source :srcset="imageSrcSet" :media="imageMobileUrl ? '(min-width: 768px)' : ''" />
        <img :src="imageLowDefinition" :alt="desktopAlt" width="200" height="118" />
      </picture>
    </div>
    <div :class="['container', 'text-center']">
      <h3 class="a11y-sr-only" v-if="item.headline" v-text="item.headline"></h3>
      <h2 class="a11y-sr-only" v-if="item.title" v-text="item.title"></h2>
      <p class="a11y-sr-only" v-if="item.subtitle" v-text="item.subtitle"></p>
      <div ref="headline" class="h3 -upper" v-if="item.headline" v-text="item.headline" aria-hidden="true" :class="[item.headline_text_color === 'white' ? '-white' : '-black', item.headline_text_color_mobile === 'white' ? '-white-mobile' : '-black-mobile']"></div>
      <div ref="title" class="h1 -medium" v-if="item.title" v-html="item.title" aria-hidden="true" :class="[item.title_text_color === 'white' ? '-white' : '-black', item.title_text_color_mobile === 'white' ? '-white-mobile' : '-black-mobile']"></div>
      <div ref="subtitle" class="h5 -medium" v-if="item.subtitle" v-text="item.subtitle" aria-hidden="true" :class="[item.subtitle_text_color === 'white' ? '-white' : '-black', item.subtitle_text_color_mobile === 'white' ? '-white-mobile' : '-black-mobile']"></div>
      <transition name="fade">
        <router-link v-if="item.cta_label && item.cta_link" ref="buttonDiscover" :class="['btn', '-large', item.cta_text_color === 'white' ? '-black' : '-white', item.cta_text_color_mobile === 'white' ? '-black-mobile' : '-white-mobile']" v-text="item.cta_label" :title="item.cta_label" :to="localizedUrl + item.cta_link" @click.native="callGTM('eePromoClick', item)"></router-link>
      </transition>
    </div>
    <button v-if="item.video" type="button" class="btn-autoPlay" :class="{'-paused': pauseVideo}" :aria-label="pauseVideo ? 'Play' : 'Pause'" @click="toggleVideo"></button>
  </section>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import {currentStoreView} from '@vue-storefront/core/lib/multistore'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import { getRouteName } from '../utilities'

export default {
  name: 'hero_image',
  props: ['item', 'pagePosition', 'siblings-components'],
  mixins: [ReadyHook],
  data() {
    return {
      pauseVideo: false
    }
  },
  computed: {
    isMobile() {
      return this.$store.state['zenith-ui'].menu.mobile
    },
    localizedUrl () {
      return currentStoreView().url || ''
    },
    imageUrl() {
      return (this.item.main_picture && this.item.main_picture.url) ? this.item.main_picture.url : null
    },
    imageMobileUrl() {
      return (this.item.mobile_picture && this.item.mobile_picture.url) ? this.item.mobile_picture.url : null
    },
    imageLowDefinition() {
      return ImageManager.getLowDefinition(this.imageUrl, 200, 0.5904550499)
    },
    imageSrcSetMobile () {
      return ImageManager.getSrcSet(this.imageMobileUrl, 1600, 200, 100, 1.4285714286)
    },
    imageSrcSet () {
      return ImageManager.getSrcSet(this.imageUrl, 2000, 200, 100, 0.5904550499)
    },
    desktopAlt() {
      if (this.item.main_picture && this.item.main_picture.alt) {
        return this.item.main_picture.alt
      } else if (this.item.title) {
        return this.item.title
      } else return ''
    }
  },
  methods: {
    enter() {
      if (!this.appeared && this.$refs.video) {
        this.appeared = true
        this.$refs.section.classList.remove('-visibility-auto')
        if (this.$refs.source.dataset.src) {
          this.$refs.source.setAttribute('src', this.$refs.source.dataset.src)
          this.$refs.source.removeAttribute('data-src')
          setTimeout(() => {
            this.$refs.video.load()
          }, 200)
        }
      } else if (this.$refs.video && this.$refs.video.paused) {
        this.$refs.video.play()
        this.pauseVideo = false
      }
    },
    leave() {
      if (this.$refs.video && !this.$refs.video.paused) {
        this.$refs.video.pause()
        this.pauseVideo = true
      }
    },
    trackCursor (e) {
      let dimensions = this.$el.getBoundingClientRect()
      let relX = e.clientX - dimensions.left
      let relY = e.clientY - dimensions.top

      this.$el.style.setProperty('--cursorX', relX + 'px')
      this.$el.style.setProperty('--cursorY', relY + 'px')
    },
    toggleVideo () {
      if (this.pauseVideo) {
        this.$refs.video.play()
      } else {
        this.$refs.video.pause()
      }
      this.pauseVideo = !this.pauseVideo
    },
    callGTM(event, item) {
      if (getRouteName(this.$route.name) === 'home') {
        let data = {
          event: event,
          nextPageVisited: this.localizedUrl + item.cta_link,
          promotions: [{
            creative: 'hero_image',
            id: 'hero_image_' + this.pagePosition,
            name: (item.subtitle ? item.title + ' - ' + item.subtitle : item.title) || 'Hero Image',
            position: this.index + 1
          }]
        }
        this.$gtm.trackEvent(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

section {
  position: relative;
  min-height: calc(90vh - 12rem);
  background: black;
  height: $hero-available-height;

  .btn-autoPlay {
    position: absolute;
  }

  @include bp(max md) {
    height: 100vh;
  }

  .picture-container {
    position: relative;
    width:100%;
    height: 100%;

    &.-overlay {
      //pointer-events: none;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width:100%;
        height: 100%;
        display: block;
        //@include bp(max md) {
        //  background: rgba(0, 0, 0, 0.5);
        //}
        //background: rgba(0, 0, 0, 0);
        background: rgba(0, 0, 0, 0.5);
        transition: all 0.4s ease-in-out;
      }
    }

    picture {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    /deep/ .obs {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    video, img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: none;

      &.-mobile {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &:after {
      position: absolute;
      display: block;
      z-index: 10;
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: radial-gradient( circle 20vmax at var(--cursorX) var(--cursorY), rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 40%, rgba(0,0,0,.5) 50%,rgba(0,0,0,0.75) 75%, rgba(0,0,0,1) 100% );

      @include bp(max md) {
        display: none;
      }
    }
  }

  .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    line-height: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include bp(max md) {
      top: auto;
      height: auto;
      bottom: 9rem;
      transform: translate(-50%, 0);
    }

    @include bp(md) {
      max-width: 52rem;
    }

    .h1 {
      margin-bottom: 0;
      color: var(--zenith-component-title);

      &.-medium {
        font-size: em(38);
        font-weight: normal;
        letter-spacing: em(0.2, 38);
      }

      &.-black {
        color: var(--zenith-black);
      }

      &.-black-mobile {
        @include bp(max md) {
          color: var(--zenith-black);
        }
      }
      &.-white {
          color: var(--zenith-white);
      }

      &.-white-mobile {
        @include bp(max md) {
            color: var(--zenith-white);
        }
      }
    }
    .h3 {
      color: var(--zenith-component-title);
      font-size: em(16);
      font-weight: normal;
      letter-spacing: em(0.2, 16);
      margin-bottom: 1.3rem;

      &.-black {
        color: var(--zenith-black);
      }

      &.-black-mobile {
        @include bp(max md) {
          color: var(--zenith-black);
        }
      }
      &.-white {
          color: var(--zenith-white);
      }

      &.-white-mobile {
        @include bp(max md) {
            color: var(--zenith-white);
        }
      }
    }
    .h5 {
      margin-top: 2rem;
      margin-bottom: 0;
      color: var(--zenith-component-title);
      line-height: 2.8rem;

      &.-medium {
        font-size: em(18);
        font-weight: normal;
        letter-spacing: em(0.2, 18);
      }

      &.-black {
        color: var(--zenith-black);
      }

      &.-black-mobile {
        @include bp(max md) {
          color: var(--zenith-black);
        }
      }
      &.-white {
          color: var(--zenith-white);
      }

      &.-white-mobile {
        @include bp(max md) {
          color: var(--zenith-white);
        }
      }
    }
    .h5 + .btn, .h1 + .btn, .h3 + .btn  {
      margin-top: 6rem;
      @include bp(max md) {
        margin-top: 2rem;
      }
    }

    &.-dark {
      .h1, .h3, .h5 {
        color: black;
      }
    }
  }
}
</style>
