const ptSnippet = (repository) => {
  require('intersection-observer')
  let script = document.createElement('script')
  script.src = `https://static.cdn.prismic.io/prismic.js?repo=${repository}&new=true`
  script.async = true
  script.defer = true
  document.body.append(script)
}

export function afterRegistration ({ Vue, config, store, isServer }) {
  if (!isServer && config.prismic && config.prismic.toolbar && config.prismic.toolbar.active) {
    ptSnippet(config.prismic.repository)
  }
}
