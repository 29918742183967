<template>
  <Intersect @enter.once="show = false" :threshold="[0]" :rootMargin="rootMargin ? rootMargin : '-120px'" v-on="$listeners">
    <section v-if="section" class="obs" :class="[{['obs-' + name]: show && !focus }, className]" ref="section">
      <slot />
    </section>
    <div v-else class="obs" :class="[{['obs-' + name]: show && !focus }, className]" ref="section">
      <slot />
    </div>
  </Intersect>
</template>

<script>
import Intersect from './Intersect'
import { ReadyHook } from 'vue-asyncready'
export default {
  name: 'Observer',
  props: ['name', 'rootMargin', 'section', 'className', 'delay'],
  mixins: [ReadyHook],
  components: {
    Intersect
  },
  data () {
    return {
      show: true,
      focus: false
    }
  },
  methods: {
    trapFocus(e) {
      this.focus = this.$el.contains(e.target)
    },
    getRootElement() {
      return this.$refs.section
    }
  },
  mounted() {
    document.addEventListener('focus', this.trapFocus, true)
  },
  destroyed() {
    document.removeEventListener('focus', this.trapFocus, true)
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/core";

.obs {
  transition: all 0.4s ease-in-out, transform 0.8s $Power3EaseOut, opacity 0.6s $SineEaseOut;

  &-fade {
    opacity: 0;
  }
  &-fade-up {
    opacity: 0;
    transform: translateY(10rem);
  }
  &-fade-down {
    opacity: 0;
    transform: translateY(-10rem);
  }
  &.delay-0 {
    transition: all 0.4s ease-in-out, transform 0.8s $Power3EaseOut, opacity 0.6s $SineEaseOut;
  }
  &.delay-1 {
    transition: all 0.4s ease-in-out, transform 0.8s 0.2s $Power3EaseOut, opacity 0.6s 0.2s $SineEaseOut;
  }
  &.delay-2 {
    transition: all 0.4s ease-in-out, transform 0.8s 0.4s $Power3EaseOut, opacity 0.6s 0.4s $SineEaseOut;
  }
  &.delay-3 {
    transition: all 0.4s ease-in-out, transform 0.8s 0.6s $Power3EaseOut, opacity 0.6s 0.6s $SineEaseOut;
  }
  &.delay-4 {
    transition: all 0.4s ease-in-out, transform 0.8s 0.8s $Power3EaseOut, opacity 0.6s 0.8s $SineEaseOut;
  }
  &.delay-5 {
    transition: all 0.4s ease-in-out, transform 0.8s 1s $Power3EaseOut, opacity 0.6s 1s $SineEaseOut;
  }
  &.delay-10 {
    transition: all 0.4s ease-in-out, transform 0.8s 1.6s $Power3EaseOut, opacity 0.6s 1.6s $SineEaseOut;
  }
}

</style>
