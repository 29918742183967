import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import config from 'config'
export function afterRegistration({ Vue, config, store, isServer }) {
  if (config.googleTagManager.id && !isServer) {
    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode
    Vue.prototype.gtm = Vue.gtm

    store.subscribe(({ type, payload }, state) => {
      if (type === 'cart/cart/ADD') {
        const { product } = payload
        const {
          name,
          collection,
          sku,
          price,
          option_value_material,
          option_value_dial,
          option_value_diameter,
          option_value_power_reserve,
          option_value_line,
          option_value_collection,
          option_value_water_resistance,
          option_value_bracelet,
          stock
        } = product

        const isWishlist = window.location.href.includes('my-profile')
        const isProduct = window.location.href.includes('product')
        const isCart = window.location.href.includes('cart')
        let page
        if (isWishlist) page = 'Wishlist'
        else if (isProduct) page = 'Product Page'
        else if (isCart) page = 'Cart'
        else page = 'Other'

        Vue.gtm.trackEvent({
          event: 'addToCart',
          addToCartType: page,
          products: [{
            name,
            id: sku,
            price,
            brand: 'Zenith',
            category: 'Watches',
            quantity: 1,
            productCase: option_value_material,
            productCaseSize: option_value_diameter,
            productColor: option_value_dial,
            productMasterId: collection,
            productMidCategory: option_value_collection,
            productPowerReserve: option_value_power_reserve,
            productSubCategory: option_value_line,
            productStock: stock && stock.is_in_stock ? 'In stock' : 'Out of stock',
            productStrap: option_value_bracelet,
            productWaterResistance: option_value_water_resistance
          }]
        })
      }
    })
    store.subscribe(({ type, payload }, state) => {
      if (type === 'cart/cart/DEL') {
        const { product } = payload
        const {
          name,
          collection,
          sku,
          price,
          option_value_material,
          option_value_dial,
          option_value_diameter,
          option_value_power_reserve,
          option_value_line,
          option_value_collection,
          option_value_water_resistance,
          option_value_bracelet,
          stock
        } = product
        Vue.gtm.trackEvent({
          event: 'removeFromCart',
          products: [{
            name,
            id: sku,
            price,
            brand: 'Zenith',
            category: 'Watches',
            quantity: 1,
            productCase: option_value_material,
            productCaseSize: option_value_diameter,
            productColor: option_value_dial,
            productMasterId: collection,
            productMidCategory: option_value_collection,
            productPowerReserve: option_value_power_reserve,
            productSubCategory: option_value_line,
            productStock: stock && stock.is_in_stock ? 'In stock' : 'Out of stock',
            productStrap: option_value_bracelet,
            productWaterResistance: option_value_water_resistance
          }]
        })
      }
    })

    //       // Measuring Purchases
    //       if (type === 'order/order/LAST_ORDER_CONFIRMATION') {
    //         const orderId = payload.confirmation.backendOrderId
    //         const products = payload.order.products.map(product => getProduct(product))
    //         store.dispatch(
    //           'user/getOrdersHistory',
    //           { refresh: true, useCache: false }
    //         ).then(() => {
    //           const orderHistory = state.user.orders_history
    //           const order = orderHistory.items.find((order) => order['entity_id'].toString() === orderId)
    //           if (order) {
    //             Vue.gtm.trackEvent({
    //               'ecommerce': {
    //                 'purchase': {
    //                   'actionField': {
    //                     'id': orderId,
    //                     'affiliation': order.store_name,
    //                     'revenue': order.total_due,
    //                     'tax': order.tax_amount,
    //                     'shipping': order.shipping_amount,
    //                     'coupon': ''
    //                   },
    //                   'products': products
    //                 }
    //               }
    //             })
    //           }
    //         })
    //       }
  }
}
