<template>
  <div ref="item" class="speedTransitionPage"
       :class="{ '-initial': initial, '-enter': enter, '-active': active, '-leave': leave }">
    <div ref="shadow" class="curtain-shadow"></div>
    <div ref="first" class="curtain --first"></div>
    <div ref="logo" class="logo"></div>
  </div>
</template>

<script>
import {Transition} from 'vue-asyncready'
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'SpeedTransitionPage',
  mixins: [Transition],
  data: () => ({
    autoClear: 700
  }),
  methods: {
    onEnter() {
    },
    onLeave() {
      if (!isServer) {
        if (!this.$refs.item.hasAttribute('data-initial')) {
          setTimeout(() => {
            this.$refs.item.dataset.initial = ''
          }, 1000)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~theme/css/settings/animations";

  $appearAnimationDuration: .6s;
  $curtainAnimationDuration: .6s;
  $curtainAnimationDelay: .1s;
  $curtainFirstColor: var(--zenith-transition-speed-curtain-background);
  $curtainSecondColor: var(--zenith-transition-speed-curtain-2-background);
  .speedTransitionPage {
    z-index: 1000;

    .curtain {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: $curtainFirstColor;
      transform: scale(1, 0);
      transform-origin: top left;
      transition: transform $appearAnimationDuration $CircEaseInOut;
    }
    .curtain-shadow {
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: $curtainSecondColor;
      transform-origin: bottom left;
      transition: transform $appearAnimationDuration $CircEaseInOut;
    }
    .logo {
      position: fixed;
      z-index: 100;
      top: 50%;
      left: 50%;
      width: 180px;
      height: 68px;
      pointer-events: none;
      background: url('/assets/img/logo-white.svg') no-repeat center / cover;
      transform: translate(-50%, -50%);
      opacity: 0;
      display: none;
    }
    &.-enter {
      .curtain {
        transform: scale(1, 1);
      }
      .logo {
        display: inline-block;
        transition: opacity 0.2s 0.2s $SineEaseOut;
        opacity: 1;
        animation: bouncing 4s linear infinite;
      }
    }
    &.-active {
      .curtain {
        transform: scale(1, 1);
      }
      .logo {
        opacity: 1;
      }
    }
    &[data-initial] {
      &.-leave{
        .curtain {
          transform-origin: bottom left;
          transition: transform $curtainAnimationDuration $CircEaseInOut;
          transform: scale(1, 0);
        }
        .curtain-shadow {
          visibility: visible;
          pointer-events: none;
          transition: transform $curtainAnimationDuration $CircEaseInOut;
          transition-delay: $curtainAnimationDelay;
          transform: scale(1, 0);
        }
        .logo {
          opacity: 0;
          transition: opacity 0.2s 0s $SineEaseOut;
        }
      }
    }
  }

@keyframes bouncing {
  0%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
  25% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  75% {
    transform: translate(-50%, -50%) scale(0.9);
  }
}
</style>
