<template>
  <section class="-bg-white">
    <div class="container text-center" :class="{'-extremeE': item.isExtremeE}">
      <h2 class="subtitle" v-if="item.headline" v-text="item.headline"></h2>
      <h2 class="title" v-if="item.title" v-text="item.title"></h2>
      <hooper :settings="hooperSettings" :class="{'-white' : true, '-small': items.length < itemsToShow}" ref="carousel" @slide="updateProgress" @updated="updateSlider" tabindex="-1">
        <slide v-for="(product, i) in items" :key="i" :index="i">
          <product-item v-if="!item.isExtremeE" :product="product" :class-obs="'delay-' + i%itemsToShow" :list-position="i" :list-name="item.title ? item.title : $t('See all the collection')" :list-type="'Page ' + $route.name.substring(6).replace('-', ' ')"></product-item>
          <extreme-e-item v-else :product="product" :class-obs="'delay-' + i%itemsToShow" :list-position="i" :list-name="item.title ? item.title : $t('See all the collection')" :list-type="'Page ' + $route.name.substring(6).replace('-', ' ')"></extreme-e-item>
        </slide>
        <div class="hooper-navigation container" slot="hooper-addons" v-if="items.length > itemsToShow">
          <button type="button" class="hooper-prev" :class="{'is-disabled': !currentSlide}" :aria-label="$t('Previous slide')" @click="() => $refs.carousel.slidePrev()"></button>
          <button type="button" class="hooper-next" :class="{'is-disabled': currentSlide >= items.length - itemsToShow}" :aria-label="$t('Next slide')" @click="() => $refs.carousel.slideNext()"></button>
        </div>
        <hooper-progress v-if="items.length > 1" slot="hooper-addons"></hooper-progress>
      </hooper>
    </div>
  </section>
</template>

<script>
import { Hooper, Progress as HooperProgress, Slide } from 'hooper'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ReadyHook } from 'vue-asyncready'
import ProductItem from './ProductItem'
import ExtremeEItem from './ExtremeEItem'
import { getProductPrice } from '../utilities'

export default {
  props: ['item', 'items', 'pagePosition', 'siblings-components', 'enter'],
  name: 'product_slider',
  mixins: [ReadyHook],
  components: {
    Hooper,
    Slide,
    HooperProgress,
    ProductItem,
    ExtremeEItem
  },
  computed: {
    localizedUrl () {
      return currentStoreView().url || ''
    }
  },
  watch: {
    enter () {
      this.$refs.carousel.update()
    }
  },
  data () {
    return {
      currentSlide: 0,
      itemsToShow: this.item.itemsToShow ? this.item.itemsToShow : 0,
      hooperSettings: {
        initialSlide: this.item.isExtremeE ? 2 : 0,
        itemsToShow: this.item.isExtremeE ? 1.25 : 2,
        transition: 400,
        autoPlay: false,
        mouseDrag: true,
        shortDrag: true,
        touchDrag: true,
        hoverPause: false,
        keysControl: true,
        wheelControl: false,
        trimWhiteSpace: true,
        infiniteScroll: false,
        centerMode: this.item.isExtremeE,
        breakpoints: {
          992: {
            initialSlide: 0,
            itemsToShow: this.item.isExtremeE ? 5 : this.item.itemsToShow ? this.item.itemsToShow : 4
          },
          768: {
            centerMode: false,
            initialSlide: this.item.isExtremeE ? 1 : 0,
            itemsToShow: this.item.isExtremeE ? 3 : this.item.itemsToShow ? this.item.itemsToShow : 3
          }
        }
      }
    }
  },
  methods: {
    getProductPrice: getProductPrice,
    updateSlider (payload) {
      this.itemsToShow = payload.settings.itemsToShow
    },
    updateProgress (payload) {
      this.currentSlide = payload.currentSlide
    },
    callGTM () {
      this.$gtm.trackEvent({
        event: 'eeListView',
        listName: this.item.title ? this.item.title : this.$t('See all the collection'),
        listType: 'Page ' + this.$route.name.substring(6).replace('-', ' '),
        products: this.items.map((product, i) => {
          return {
            name: product.name || '',
            id: product.sku || '',
            position: i + 1,
            price: getProductPrice(product) || '',
            brand: 'Zenith',
            category: 'Watches',
            productCase: product.option_value_material || '',
            productCaseSize: product.option_value_diameter || '',
            productColor: product.option_value_dial || '',
            productMasterId: product.collection || '',
            productSubCategory: product.option_value_line,
            productMidCategory: product.option_value_collection || '',
            productPowerReserve: product.option_value_power_reserve || '',
            productStock: product.isInStock ? 'In stock' : 'Out of stock',
            productStrap: product.option_value_bracelet || '',
            productWaterResistance: product.option_value_water_resistance || ''
          }
        })
      })
    }
  },
  mounted () {
    this.callGTM()
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

section {
  margin: 10rem 0;

  @include bp(max md) {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  /deep/ + .cta {
    margin-top: -6rem;
    @include bp(max md) {
      margin-top: -2rem;
    }
  }

  .container {
    padding-left: 3rem;
    padding-right: 3rem;

    @include bp(max md) {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .title {
      position: relative;
      z-index: 20;
      margin-bottom: 8rem;
      color: var(--zenith-black);
      @include bp(max md) {
        margin-bottom: 4rem;
      }
    }

    .subtitle {
      position: relative;
      z-index: 20;
      font-weight: normal;
      font-size: em(16);
      letter-spacing: em(2, 16);
      margin-bottom: 0.4rem;
      text-transform: uppercase;
      color: var(--zenith-black);
    }

    &.-extremeE {
      max-width: 154rem;

      @include bp(max md) {
        padding: 0;
      }

      .hooper {
        margin-top: 3rem;

        @include bp(max md) {
          /deep/ {
            .hooper-slide {
              .headline, .btn, .price, .title {
                opacity: 0;
              }

              &.is-active {
                .headline, .btn, .price, .title {
                  opacity: 1;
                }
              }
            }
          }
        }

        /deep/ .hooper-navigation {
          top: 45%;

          button {
            opacity: 1;
          }
        }
      }

      .title {
        margin-bottom: 3rem;
        @include bp(max md) {
          margin-bottom: 2rem;
        }
      }
    }
  }
}

.-icons .title {
  font-weight: normal;
  display: inline;
  font-size: em(24);
  color: var(--zenith-white) !important;
  background: var(--zenith-black) !important;
  box-shadow: -2px 0px 0px 6px var(--zenith-black);
}

.hooper {
  margin: 6rem 0 0;
  padding: 0;
  height: auto;
  outline: none;

  &.-small {
    font-size: em(14);

    /deep/ .hooper-track {
      justify-content: center;
      transform: none !important
    }

    /deep/ .hooper-liveregion.hooper-sr-only {
      display: none;
    }
  }

  /deep/ .hooper-slide {
    position: relative;
    outline: none;
    height: auto;
    padding: 1rem 1rem;

    /deep/ .obs {
      height: 100%;
    }

    /deep/ > .obs {
      transform: translateY(0);

      &.obs-fade-up {
        transform: translateY(10rem);
      }
    }
  }
}

.product {
  position: relative;
  // background: var(--zenith-product-item-background);
  padding: 2rem 2rem 3rem;
  overflow: hidden;
  height: 100%;

  .link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
  }

  @include bp(max md) {
    padding: 1rem;
  }

  .fav {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 30;
    background: none;
    border: none;
    padding: 0;
    margin: 0;

    &:hover, &:focus, &:active {
      transform: scale(1.1);
    }

    i {
      display: block;
    }

    @include bp(max md) {
      top: 1.5rem;
      right: 1.5rem;
    }
  }

  span {
    display: block;
    margin-bottom: 1rem;
    text-transform: uppercase;
    transition: all 0.4s ease-in-out;
    pointer-events: none;
  }

  .watch {
    max-width: 100%;
    margin-bottom: 2rem;
    transition: all 2s cubic-bezier(.075, .82, .165, 1);
  }

  .cover {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }

  .btn {
    position: absolute;
    z-index: 30;
    bottom: 4rem;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    transform: translate(-50%, 1rem);

    @include bp(max md) {
      bottom: 2rem;
    }
  }

  &:hover,
  &:focus,
  &:active {
    span {
      opacity: 0;
    }

    .watch {
      transform: scale(1.2);
    }

    .cover {
      opacity: 1;
      transform: scale(1);
    }

    .btn {
      opacity: 1;
      pointer-events: all;
      transform: translate(-50%, 0);
    }

    .fav {
      color: var(--zenith-white);
    }
  }
}

/deep/ .hooper-navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  @include bp(max md) {
    display: none;
  }

  .hooper-prev {
    left: -1.5rem;
    outline: none;

    &:before {
      content: "\e903";
    }

    &:hover {
      &:before {
        @include pillPrevHover();
      }
    }
  }

  .hooper-next {
    right: -1.5rem;
    outline: none;

    &:before {
      content: "\e925";
    }

    &:hover {
      &:before {
        @include pillNextHover();
      }
    }
  }

  button {
    opacity: 0.5;
    padding: 1.3rem;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    background: #f8f8f8;

    svg {
      display: none;
    }

    &:before {
      display: block;
      font-family: 'zenith', sans-serif !important;
      speak: none;
      font-size: em(20);
      line-height: 2rem;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &.is-disabled {
      opacity: 0 !important;
      pointer-events: none;
    }

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  }
}

/deep/ .hooper-progress {
  position: relative;
  background: #cccccc;
  height: .2rem;
  margin-top: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;

  @include bp(md) {
    display: none;
  }

  &-inner {
    background: #000;
  }
}
</style>
