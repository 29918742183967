import { Route } from 'vue-router'
/*
import rootStore from '@vue-storefront/core/store'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
*/
import { deleteDWCookies, clearLocalCart } from '../../../themes/zenith/utilities/session-bridge'

export function beforeEach(to: Route, from: Route, next) {
/*
  const cartToken: string = rootStore.state.cart.cartServerToken;
  const userToken: string = rootStore.state.user.token;
  const externalCheckoutConfig = { ...config.externalCheckout };
  const cmsUrl: string = externalCheckoutConfig.cmsUrl;
  const stores = externalCheckoutConfig.stores;
  const {storeCode, virtualStoreCode} = currentStoreView()
  const url = currentStoreView().url
  const multistoreEnabled: boolean = config.storeViews.multistore
*/
  const isFirstRouting = !from || !from.name
  if (to.name && to.path.includes('/checkout') && !isFirstRouting) {
    clearLocalCart()
    deleteDWCookies()
    window.location.href = to.path
  } else {
    next()
  }
  /*
  if (multistoreEnabled && !isFirstRouting) {
    if (storeCode in stores && to.name === storeCode + '-checkout') {
      window.location.href = stores[storeCode].cmsUrl + '/vue/cart/sync/token/' + userToken + '/cart/' + cartToken + `?storeCode=${virtualStoreCode}` + `&pwaStoreCode=${storeCode}`
    } else if (storeCode in stores && to.name === 'checkout' && stores[storeCode].cmsUrl !== undefined) {
      window.location.href = stores[storeCode].cmsUrl + '/vue/cart/sync/token/' + userToken + '/cart/' + cartToken + `?storeCode=${virtualStoreCode}` + `&pwaStoreCode=?${storeCode}`
    } else {
      next()
    }
  } else {
    if (to.name === 'checkout' && !isFirstRouting) {
      window.location.href = cmsUrl + '/vue/cart/sync/token/' + userToken + '/cart/' + cartToken + `?storeCode=${virtualStoreCode}` + `&pwaStoreCode=${storeCode}`
    } else {
      next()
    }
  } */
}
