import { MutationTree } from 'vuex'
import * as types from './mutation-types'

export const mutations: MutationTree<any> = {
  [types.SET_CODE](state, { code }) {
    state.code = code
    if (code) {
      state.overlay = true
    }
  },
  [types.SET_OVERLAY](state, { overlay }) {
    state.overlay = overlay
    if(!overlay) {
      state.code = null
    }
  },
}
