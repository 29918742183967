import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

export function productsQuery() {
  let query: SearchQuery = new SearchQuery()
  return query
}
export function productQuery({ sku }) {
  let query: SearchQuery = new SearchQuery()
  query = query.applyFilter({ key: 'sku', value: { eq: sku } })
  return query
}
