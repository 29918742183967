import { currentStoreView } from '@vue-storefront/core/lib/multistore'

// todo globale straps: if globale includes straps one day, modification have to be made on this part of the code
export const getProductPrice = product => {
  if (!product) return 0
  const { is_global_e_store: isGlobalEStore, i18n } = currentStoreView()
  const { defaultCountry } = i18n
  if (isGlobalEStore) {
    if (product && product.price_by_currency) {
      const item = product.price_by_currency.find(
        x => defaultCountry === x.country_code
      )
      if (item) {
        const { price } = item
        return +price
      }
      return 0
    } else {
      return 0
    }
  } else if (
    product.product_option &&
    product.product_option.extension_attributes &&
    product.product_option.extension_attributes.bundle_options
  ) {
    // if product has options selected -> option buckle + option strap = price
    return product.product_option.extension_attributes.bundle_options.reduce(
      (accumulator, current) => {
        let price = 0
        const option = product.bundle_options.find(
          item => +item.option_id === +current.option_id
        )
        if (option) {
          const { product_links: productLinks } = option
          let prices = productLinks
            .map(link =>
              link.id === current.option_selections[0] ? link.price : 0
            )
            .reduce((acc, curr) => {
              acc += curr
              return acc
            })
          price = prices && prices > 0 ? prices : 0
        }
        accumulator += price * (current.option_qty || 1)
        return accumulator
      },
      0
    )
  } else if (product.bundle_options && product.bundle_options.length) {
    // Extract default price from options !== Product price after options selection by user
    const bundleOption = product.bundle_options.find(
      item => item.title === 'Strap'
    )
    const prices = bundleOption.product_links.map(({ price }) => +price)
    const notNull = prices.filter(price => price)
    const highest = notNull.reduce((acc, curr) => {
      acc = curr > acc ? curr : acc
      return acc
    }, 0)
    return highest
  } else {
    return +product.price
  }
}
