import config from 'config'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export const addWarrantyByPan = (payload) => {
  const url = `${config.api.url}/api/ext/warranty/add?token={{token}}&pan=true`
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(payload)
    },
    silent: true
  })
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      }
      return json.result
    })
}

export const addWarrantyExtended = (payload) => {
  const {storeCode } = currentStoreView()
  const url = `${config.api.url}/api/ext/warranty/add?token={{token}}`
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({...payload, storeCode})
    },
    silent: true
  })
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      }
      return json.result
    })
}

export const get = (payload) => {
  const url = `${config.api.url}/api/ext/warranty/get?token={{token}}`
  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify(payload)
    },
    silent: true
  })
    .then(json => {
      if (json.result && json.result.message) {
        throw new Error(JSON.stringify(json))
      } else {
        return json.result && json.result.body && json.result.body.items ? json.result.body.items : null
      }
    })
}
