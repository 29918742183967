import { FeedbackState } from '../types/FeedbackState'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'

export const actions: ActionTree<FeedbackState, any> = {
  async show({ commit }, { message, context = 'info', show = true, duration = 120000 }) {
    commit(types.SHOW_FEEDBACK, { message, context, show, duration })
    return Promise.resolve(null)
  },
}
